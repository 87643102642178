import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useInputHandler from "hooks/useInputHandler";
import useSelectBox from "hooks/useSelectBox";
import useFindPostAddr from "hooks/useFindPostAddr";
import useDatePicker from "hooks/useDatePicker";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import useValidation from "hooks/useValidation";
import { useEffect, useState } from "react";

export default function StudentAdd() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [dataForm, setDataForm, attributes] = useInputHandler({
    student_name: "",
    student_gender: "남",
    student_birth: "",
    student_status: "status_1",
    postal_code: "",
    address: "",
    detail_address: "",
    latitude: "",
    longitude: "",
    parent_phone_number: "",
    memo: "",
    class_list: [],
  });
  const [studentPhoneNumber, setStudentPhoneNumber] = useState("");

  const [SelectBox, selectValue] = useSelectBox([
    { name: "재학", code: "status_1" },
    { name: "휴학", code: "status_2" },
  ]);
  const { DatePickerModule, specificDate } = useDatePicker();
  const { DaumPost, userAddr, openDaumPost, position } = useFindPostAddr(true);
  const { form, validPass, errorCheck, validAttributes } = useValidation();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "레벨", code: "level" },
    { name: "일정", code: "class_schedule" },
    { name: "수업시간", code: "class_time" },
    { name: "선생님", code: "mna_teacher_name" },
    { name: "수업장소", code: "class_room" },
    { name: "과목", code: "class_field" },
    { name: "수강료 납입일", code: "payment_date" },
  ]);

  const listDataFormat = (type, data, idx) => {
    switch (type) {
      case "class_schedule": {
        return data.class_time.map((el) => {
          return (
            <>
              {el.week}
              <br />
            </>
          );
        });
      }
      case "class_time": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.start_time.slice(0, -3)}~{el.end_time.slice(0, -3)}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_room": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.room_name}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_shuttle": {
        return (
          <div className="btn_group flex jc_c">
            <button
              className={
                data.shuttle_bus_stop_ids &&
                data.shuttle_bus_stop_ids.some((el) => {
                  return el.riding?.stop_id || el.quit?.stop_id;
                })
                  ? "btn_default slim del"
                  : "btn_default slim green3"
              }
              onClick={() => {
                if (!userAddr.addr) {
                  return setMessage("학생 집주소를 먼저 입력해 주세요.");
                }
                if (!position.latitude || !position.longitude) {
                  return setMessage(
                    "위치정보를 불러오는 중입니다. 잠시 후 다시 시도해 주세요."
                  );
                }
                navigate("select-shuttle", {
                  state: {
                    data: {
                      ...data,
                      idx,
                      position,
                      type: "신청",
                    },
                  },
                });
              }}
            >
              {data.shuttle_bus_stop_ids &&
              data.shuttle_bus_stop_ids.some((el) => {
                return el.riding?.stop_id || el.quit?.stop_id;
              })
                ? "상세"
                : "신청"}
            </button>
          </div>
        );
      }
      case "payment_date": {
        return (
          <div className="cell_content_wrap">
            <input
              type="number"
              value={data.payment_date}
              data-idx={idx}
              onChange={setPaymentDate}
            />
          </div>
        );
      }
      case "class_delete": {
        return (
          <div className="btn_group flex jc_c">
            <button
              className="btn_default slim del"
              onClick={() => {
                deleteClass(data);
              }}
            >
              삭제
            </button>
          </div>
        );
      }
      default: {
        return data[type];
      }
    }
  };

  const setPaymentDate = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");
    if (val > 31) return (val = 31);
    if (val < 1) return (val = 1);
    let copy = { ...dataForm };
    copy.class_list[e.target.dataset.idx].payment_date = val;
    setDataForm(copy);
  };

  const submitData = async () => {
    let copy = JSON.parse(JSON.stringify(dataForm));
    copy.student_name = form.mb_name.val;
    copy.parent_phone_number = form.mb_ph.val;
    copy.student_status = selectValue.code;
    copy.student_birth = formatDate(specificDate);
    copy.student_phone_number = studentPhoneNumber.val;
    copy.latitude = position.latitude;
    copy.longitude = position.longitude;
    copy.postal_code = userAddr.zonecode;
    copy.address = userAddr.addr;
    copy.class_list = copy.class_list.map((el) => {
      if (el.shuttle_bus_stop_ids) {
        el.shuttle_bus_stop_ids = el.shuttle_bus_stop_ids.map((stop) => {
          const stop_ids = [];
          if (stop.riding?.stop_id) stop_ids.push(stop.riding?.stop_id);
          if (stop.quit?.stop_id) stop_ids.push(stop.quit?.stop_id);
          return {
            classtime_id: stop.class_time_id,
            stop_ids,
          };
        });
      }
      el.classtime_ids = el.shuttle_class_list.map((el) => el.class_time_id);
      return el;
    });
    const res = await requestAPI("put", "student/sign-up", copy);
    if (!res) return;
    setMessage({ text: alertMsg.add, confirm: () => navigate("/student/1") });
  };

  const submitInviteData = async () => {
    const res = await requestAPI("post", "milivily/member/send-sms", {
      phone_number: form.mb_ph.val,
      student_name: form.mb_name.val,
      student_birth: formatDate(specificDate),
    });
    if (!res) return;
    setMessage(alertMsg.invite);
  };

  const selectClass = (data) => {
    let copy = { ...dataForm };
    copy.class_list.push({ ...data, payment_date: 1 });
    setDataForm(copy);
  };

  const deleteClass = (data) => {
    let copy = { ...dataForm };
    const targetIdx = copy.class_list.findIndex(
      (el) => el.class_id === data.class_id
    );
    copy.class_list.splice(targetIdx, 1);
    setDataForm(copy);
  };

  const selectShuttle = (data) => {
    let copy = { ...dataForm };
    const target = copy.class_list[data.targetIdx];
    target.shuttle_bus_stop_ids = data.shuttleData;
    setDataForm(copy);
  };

  if (pathname !== "/student/add") {
    return (
      <Outlet
        context={{
          selectClass,
          deleteClass,
          classList: dataForm.class_list,
          selectShuttle,
        }}
      />
    );
  } else
    return (
      <div id="student" className="container background_white">
        <div className="inner">
          <Loading />
          <section className="page_content_wrap studnet_info">
            <header>
              <h2>학생등록</h2>
              <div className="btn_group">
                <ReturnToList />
              </div>
            </header>

            <table className="detail desktop">
              <colgroup>
                <col width={"160px"} />
                <col width={"auto"} />
                <col width={"160px"} />
                <col width={"35%"} />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_name")?.state}
                      {...validAttributes("mb_name")}
                    />
                  </div>
                </td>
                <th>성별</th>
                <td>
                  <div className="cell_content_wrap">
                    <label htmlFor="man">
                      남
                      <input
                        type="checkbox"
                        name="gender"
                        id="man"
                        className="ml_4"
                        checked={dataForm.student_gender === "남"}
                        {...attributes("student_gender", "남")}
                      />
                    </label>
                    <label htmlFor="woman">
                      여
                      <input
                        type="checkbox"
                        name="gender"
                        id="woman"
                        className="ml_4"
                        checked={dataForm.student_gender === "여"}
                        {...attributes("student_gender", "여")}
                      />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td className="student_state">
                  <div className="cell_content_wrap">
                    <SelectBox />
                  </div>
                </td>
                <th>생년월일</th>
                <td>
                  <div className="cell_content_wrap">
                    <DatePickerModule
                      type="specific"
                      minDate={"off"}
                      maxDate={"on"}
                      className="full"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td colSpan={3}>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className="small pointer"
                      placeholder="우편번호"
                      value={userAddr.zonecode}
                      onClick={openDaumPost}
                      readOnly
                    />
                    <input
                      type="text"
                      className="pointer"
                      placeholder="기존주소"
                      value={userAddr.addr}
                      onClick={openDaumPost}
                      readOnly
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default slim small green3"
                        onClick={openDaumPost}
                      >
                        주소검색
                      </button>
                    </div>
                    <input
                      type="text"
                      placeholder="상세주소"
                      {...attributes("detail_address")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  보호자
                  <br />
                  연락처
                </th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_ph")?.state}
                      data-format="number"
                      {...validAttributes("mb_ph")}
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default slim small green3"
                        onClick={submitInviteData}
                      >
                        초대하기
                      </button>
                    </div>
                  </div>
                </td>
                <th>
                  학생
                  <br /> 연락처
                </th>
                <td>
                  <StudentPhoneNumber
                    studentPhoneNumber={studentPhoneNumber}
                    setStudentPhoneNumber={setStudentPhoneNumber}
                  />
                </td>
              </tr>
            </table>

            <table className="detail fixed mobile">
              <colgroup>
                <col width={"22%"} />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_name")?.state}
                      {...validAttributes("mb_name")}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th>성별</th>
                <td>
                  <div className="cell_content_wrap">
                    <label htmlFor="man">
                      남
                      <input
                        type="checkbox"
                        name="gender"
                        id="man"
                        className="ml_4"
                        checked={dataForm.student_gender === "남"}
                        {...attributes("student_gender", "남")}
                      />
                    </label>
                    <label htmlFor="woman">
                      여
                      <input
                        type="checkbox"
                        name="gender"
                        id="woman"
                        className="ml_4"
                        checked={dataForm.student_gender === "여"}
                        {...attributes("student_gender", "여")}
                      />
                    </label>
                  </div>
                </td>
              </tr>

              <tr>
                <th>상태</th>
                <td className="student_state">
                  <div className="cell_content_wrap">
                    <SelectBox />
                  </div>
                </td>
              </tr>

              <tr>
                <th>생년월일</th>
                <td>
                  <div className="cell_content_wrap">
                    <DatePickerModule
                      type="specific"
                      minDate={"off"}
                      maxDate={"on"}
                      className="full"
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th>주소</th>
                <td>
                  <div className="cell_content_wrap address">
                    <div className="postcode_wrap">
                      <input
                        type="text"
                        className="pointer"
                        placeholder="우편번호"
                        value={userAddr.zonecode}
                        onClick={openDaumPost}
                        readOnly
                      />
                      <div className="btn_group short">
                        <button
                          className="btn_default small slim green3"
                          onClick={openDaumPost}
                        >
                          주소검색
                        </button>
                      </div>
                    </div>

                    <input
                      type="text"
                      className="pointer"
                      value={userAddr.addr}
                      onClick={openDaumPost}
                      readOnly
                    />

                    <input
                      type="text"
                      placeholder="상세주소"
                      {...attributes("detail_address")}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th>
                  보호자
                  <br />
                  연락처
                </th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_ph")?.state}
                      data-format="number"
                      {...validAttributes("mb_ph")}
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default slim small green3"
                        onClick={submitInviteData}
                      >
                        초대하기
                      </button>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th>
                  학생
                  <br /> 연락처
                </th>
                <td>
                  <StudentPhoneNumber
                    studentPhoneNumber={studentPhoneNumber}
                    setStudentPhoneNumber={setStudentPhoneNumber}
                  />
                </td>
              </tr>
            </table>
          </section>

          <section className="page_content_wrap select_class_list mt_56">
            <header>
              <h2>선택 수업 리스트</h2>
              <div className="btn_group">
                <button
                  className="btn_default"
                  onClick={() => {
                    navigate("select-class");
                  }}
                >
                  수업리스트에서 선택
                </button>
              </div>
            </header>

            <table className="detail fixed border_none outline_none">
              <colgroup className="mobile">
                <col width={"25%"} />
                <col width={"35%"} />
                <col width={"20%"} />
                <col width={"20%"} />
              </colgroup>
              <colgroup className="desktop">
                <col width={"10%"} />
                <col width={"5%"} />
                <col width={"15%"} />
                <col width={"15%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"5%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"10%"} />
              </colgroup>

              <thead>
                <tr className="mobile">
                  <th>수업명</th>
                  <th>
                    <McBoardContent />
                  </th>
                  <th>셔틀버스</th>
                  <th>선택</th>
                </tr>
                <tr className="desktop">
                  <th>수업명</th>
                  <th>레벨</th>
                  <th>일정</th>
                  <th>수업시간</th>
                  <th>선생님</th>
                  <th>수업장소</th>
                  <th>과목</th>
                  <th>셔틀버스</th>
                  <th>
                    수강료
                    <br />
                    납입일
                  </th>
                  <th>선택</th>
                </tr>
              </thead>
              <tbody>
                {dataForm.class_list.map((el, idx) => (
                  <tr key={idx}>
                    <td className="hide_text">
                      {listDataFormat("class_name", el)}
                    </td>
                    {/* 모바일 */}
                    <td className="mobile">
                      {listDataFormat(selectMcBoardContent.code, el)}
                    </td>
                    {/* 모바일 */}
                    <td className="desktop">{listDataFormat("level", el)}</td>
                    <td className="desktop">
                      {listDataFormat("class_schedule", el)}
                    </td>
                    <td className="desktop">
                      {listDataFormat("class_time", el)}
                    </td>
                    <td className="desktop">
                      {listDataFormat("mna_teacher_name", el)}
                    </td>
                    <td className="desktop hide_text">
                      {listDataFormat("class_room", el)}
                    </td>
                    <td className="desktop">
                      {listDataFormat("class_field", el)}
                    </td>
                    <td>{listDataFormat("class_shuttle", el, idx)}</td>
                    <td className="desktop">
                      {listDataFormat("payment_date", el, idx)}
                    </td>
                    <td>{listDataFormat("class_delete", el)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>

          <div className="btn_group desktop">
            <button></button>
            <button
              className="btn_default half"
              onClick={submitData}
              disabled={
                !validPass(["mb_name", "mb_ph"]) ||
                (studentPhoneNumber.val && !studentPhoneNumber.isValid)
              }
            >
              등록
            </button>
          </div>
          <div className="btn_group mobile">
            <button
              className="btn_default "
              onClick={submitData}
              disabled={
                !validPass(["mb_name", "mb_ph"]) ||
                (studentPhoneNumber.val && !studentPhoneNumber.isValid)
              }
            >
              등록
            </button>
          </div>
        </div>
        <DaumPost />
      </div>
    );
}

function StudentPhoneNumber({ studentPhoneNumber, setStudentPhoneNumber }) {
  const { form, setForm, validAttributes, errorCheck, exp } = useValidation();
  useEffect(() => {
    if (studentPhoneNumber.val) {
      setForm({
        ...form,
        mb_ph: {
          val: studentPhoneNumber.val,
          isValid: exp.mb_ph.test(studentPhoneNumber.val),
        },
      });
    }
  }, []);

  useEffect(() => {
    setStudentPhoneNumber(form.mb_ph);
  }, [form]);

  return (
    <div className="cell_content_wrap">
      <input
        type="text"
        className={errorCheck("mb_ph")?.state}
        data-format="number"
        {...validAttributes("mb_ph")}
        placeholder="선택정보"
      />
    </div>
  );
}
