import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useModalHandler from "hooks/useModalHandler";
import useDatePicker from "hooks/useDatePicker";
import useInputHandler from "hooks/useInputHandler";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import formatPriceKR from "utils/formatPriceKR";

export default function AcademyScheduleEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { ModalComponent, openModal, sendModalData, currentModal } =
    useModalHandler();
  const { DatePickerModule, startDate, endDate } = useDatePicker({
    startDate: new Date(state.resData.details.start_date),
    endDate: new Date(state.resData.details.end_date),
  });
  const [dataForm, setDataForm, attributes] = useInputHandler({
    ...state.resData.details,
    class_room_time: JSON.parse(state.resData.details.class_room_time).map(
      (el) => {
        return {
          ...el,
          start_time: el.start_time.slice(0, -3),
          end_time: el.end_time.slice(0, -3),
          existing_data: true,
        };
      }
    ),
  });

  const [
    SelectBox_subject,
    selectValue_subject,
    setSelectValue_subject,
    setSelectList_subject,
  ] = useSelectBox([]);
  const [SelectBox_level, selectValue_level, setSelectValue_level] =
    useSelectBox([
      { name: 1, code: 1 },
      { name: 2, code: 2 },
      { name: 3, code: 3 },
    ]);
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [deleteTime, setDeleteTime] = useState([]);
  const [isDupTeacher, setIsDupTeacher] = useState(false);

  const submitCondition = () => {
    return (
      dataForm.class_name &&
      dataForm.class_room_time[0] &&
      dataForm.price &&
      dataForm.mna_teacher_name &&
      dataForm.class_capacity
    );
  };

  const getData = async () => {
    const res = await requestAPI("post", "academy/field", {});
    if (!res) return;
    const subjectList = res.data.data.map((el) => {
      return { name: el.fd_name, code: el.fd_code };
    });
    setSelectValue_subject(
      subjectList.find((el) => el.code === state.resData.details.field_code)
    );
    setSelectList_subject(subjectList);
    const level = state.resData.details.level;
    setSelectValue_level({ name: level, code: level });
  };

  const submitConfirm = () => {
    if (!dataForm.class_room_time[0])
      return setMessage("수업을 추가해 주세요.");
    if (!dataForm.mna_manager_id)
      return setMessage("담당 선생님을 선택해 주세요.");
    if (state.resData.student_list.length > dataForm.class_capacity)
      return setMessage("수업 정원은 참여 학생 수 이상이여야 합니다.");
    if (dataForm.class_capacity > scheduleCapacity()) {
      setMessage({
        type: "confirm",
        text: "수업 정원이 강의실 정원을 초과하였습니다.\n그래도 등록 하시겠습니까?",
        confirm: submitData,
      });
    } else submitData();
  };

  const submitData = async () => {
    const body = {
      ...dataForm,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      field_code: selectValue_subject.code,
      level: selectValue_level.code,
      class_time_ids_deleted: [],
    };
    delete body.mna_teacher_name;
    const res = await requestAPI("patch", "academy/class/update", body);
    if (!res) return;
    setMessage({
      text: alertMsg.edit,
      confirm: () => navigate("/academy/schedule/1"),
    });
  };

  const deleteTimeConfirm = (e) => {
    const idx = e.target.dataset.idx;
    let times = dataForm.class_room_time;
    if (times[idx].existing_data) {
      setMessage({
        type: "confirm",
        text: "기존 수업시간 삭제 시 수정 버튼을 누르지\n않아도 본 수업에서 해당 수업시간이 즉시\n삭제됩니다. 그래도 삭제 하시겠습니까?",
        confirm: () => {
          if (times.length <= 1) {
            setMessage({
              type: "confirm",
              text: "마지막 남은 기존 수업시간 삭제 시\n본 수업은 운영이 종료됩니다.\n그래도 삭제 하시겠습니까?",
              confirm: () => {
                deleteTimeData(idx);
              },
            });
          } else deleteTimeData(idx);
        },
        next: times.length <= 1,
      });
    } else deleteTimeData(idx);
  };

  const deleteTimeData = async (idx) => {
    let copy = [...dataForm.class_room_time];
    if (copy[idx].existing_data) {
      const body = {
        mna_manager_id: dataForm.mna_manager_id,
        mna_teacher_id: dataForm.mna_teacher_id,
        class_id: dataForm.class_id,
        class_time_id: copy[idx].class_time_id,
      };
      const res = await requestAPI("delete", "academy/class/time-delete", body);
      if (!res) return;
      if (copy.length <= 1) {
        const body = {
          is_opening: false,
          class_id: dataForm.class_id,
        };
        const res = await requestAPI("patch", "academy/class/open", body);
        if (!res) return;
        setMessage(alertMsg.class_time_delete_last);
        navigate("/academy/schedule/1");
      } else {
        setMessage(alertMsg.class_time_delete);
      }
    }
    if (copy[idx].class_time_id[0]) {
      setDeleteTime([...deleteTime, ...copy[idx].class_time_id]);
    }
    copy.splice(idx, 1);
    setDataForm({ ...dataForm, class_room_time: copy });
  };

  const scheduleCapacity = () => {
    if (!dataForm.class_room_time[0]) return 0;
    return Math.min(
      ...dataForm.class_room_time.map((el) => {
        return Number(el.capacity);
      })
    );
  };

  const teacherAvailability = async (timeData, mna_manager_id) => {
    const res = await requestAPI("post", "academy/class/manager", {
      class_room_time: timeData.filter((el) => !el?.existing_data),
      mna_manager_id,
    });
    if (!res) return;
    if (res.data.data.class_time.is_valid) setIsDupTeacher(true);
    else setIsDupTeacher(false);
  };

  useEffect(() => {
    teacherAvailability(dataForm.class_room_time, dataForm.mna_manager_id);
  }, [dataForm.class_room_time, dataForm.mna_manager_id]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    switch (currentModal.type) {
      case "selectTime":
      case "selectSpecialTime": {
        const mergeData = [
          ...dataForm.class_room_time,
          {
            ...sendModalData,
            class_time_id: [],
          },
        ];
        return setDataForm({
          ...dataForm,
          class_room_time: mergeData,
        });
      }
      case "selectTeacher": {
        return setDataForm({
          ...dataForm,
          mna_manager_id: sendModalData.id,
          mna_teacher_id: sendModalData.teacer_id,
          mna_teacher_name: sendModalData.name,
        });
      }
      default: {
        return;
      }
    }
  }, [sendModalData]);

  return (
    <div className="inner schedule_add">
      <Loading />
      <header>
        <h2>시간표 수정({dataForm.type})</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap schedule_add">
        <table className="detail fixed">
          <tbody>
            <tr className="desktop">
              <th>수업명</th>
              <td>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("class_name")} />
                </div>
              </td>
              <th>과목</th>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox_subject />
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>수업명</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("class_name")} />
                </div>
              </td>
            </tr>
            <tr className="mobile">
              <th>과목</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <SelectBox_subject />
                </div>
              </td>
            </tr>

            <tr className="desktop">
              <th rowSpan={2}>시간</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <div className="btn_group">
                    <button
                      className="btn_default slim green3"
                      onClick={() => {
                        openModal(
                          "selectTime",
                          { 일반: "ga", 특강: "sp" }[dataForm.type],
                          dataForm.class_room_time
                        );
                      }}
                    >
                      추가
                    </button>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <th className="mobile">시간</th>
              <td colSpan={3} className="select_list">
                <div className="cell_content_wrap">
                  {dataForm.class_room_time.map((el, idx) => {
                    return (
                      <span key={idx} className="select_item">
                        {`${el.week || el.date} / ${el.start_time} - ${
                          el.end_time
                        } / ${el.room}`}
                        <button data-idx={idx} onClick={deleteTimeConfirm}>
                          &times;
                        </button>
                      </span>
                    );
                  })}
                  <button
                    className="add_schedule_time mobile"
                    onClick={() => {
                      openModal(
                        "selectTime",
                        { 일반: "ga", 특강: "sp" }[dataForm.type],
                        dataForm.class_room_time
                      );
                    }}
                  >
                    +
                  </button>
                </div>
              </td>
            </tr>
            {dataForm.type === "일반" && (
              <tr>
                <th>기간</th>
                <td colSpan={3}>
                  <div className="cell_content_wrap date">
                    <DatePickerModule type="start" />~
                    {!dataForm.is_end_date ? (
                      <DatePickerModule
                        type="end"
                        readOnly={dataForm.is_end_date}
                      />
                    ) : (
                      <input
                        type="text"
                        className="indefinite"
                        readOnly
                      ></input>
                    )}
                    <label htmlFor="isEndDate">
                      <input
                        type="checkbox"
                        id="isEndDate"
                        className="mr_8"
                        checked={dataForm.is_end_date}
                        onChange={(e) => {
                          let is_end_date;
                          if (e.target.checked) is_end_date = true;
                          else is_end_date = false;
                          setDataForm({ ...dataForm, is_end_date });
                        }}
                      />
                      종료일 없음
                    </label>
                  </div>
                </td>
              </tr>
            )}

            <tr className="desktop">
              <th>담당</th>
              <td>
                <div className="cell_content_wrap">
                  <input
                    className={"teacher" + (isDupTeacher ? " error" : "")}
                    type="text"
                    value={
                      dataForm?.mna_teacher_name +
                      (isDupTeacher ? " (시간중복)" : "")
                    }
                    readOnly
                  />
                  <div className="btn_group short">
                    <button
                      className="btn_default  slim green3"
                      onClick={() => {
                        openModal("selectTeacher", "edit", {
                          mna_manager_id: state.resData.details.mna_manager_id,
                          class_room_time: dataForm.class_room_time,
                        });
                      }}
                      disabled={!dataForm.class_room_time[0]}
                    >
                      선택
                    </button>
                  </div>
                </div>
              </td>

              <th>레벨</th>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox_level />
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>담당</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input
                    className={
                      "teacher desktop " + (isDupTeacher ? " error" : "")
                    }
                    type="text"
                    value={
                      dataForm?.mna_teacher_name +
                      (isDupTeacher ? " (시간중복)" : "")
                    }
                    readOnly
                  />
                  <input
                    className={"teacher " + (isDupTeacher ? " error" : "")}
                    type="text"
                    value={
                      dataForm?.mna_teacher_name +
                      (isDupTeacher ? " (시간중복)" : "")
                    }
                    readOnly
                  />
                  <div className="btn_group">
                    <button
                      className="btn_default  slim green3"
                      onClick={() => {
                        openModal("selectTeacher", "edit", {
                          mna_manager_id: state.resData.details.mna_manager_id,
                          class_room_time: dataForm.class_room_time,
                        });
                      }}
                      disabled={!dataForm.class_room_time[0]}
                    >
                      선택
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="mobile">
              <th>레벨</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <SelectBox_level />
                </div>
              </td>
            </tr>

            <tr>
              <th>수업내용</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <textarea {...attributes("memo")} />
                </div>
              </td>
            </tr>
            <tr>
              <th>교재</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("book")} />
                </div>
              </td>
            </tr>
            <tr>
              <th>부교재</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("sub_book")} />
                </div>
              </td>
            </tr>
            <tr className="desktop">
              <th>수업료</th>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("price")}
                    data-format="number"
                  />
                  <span className="unit hide_text">
                    {dataForm.price ? formatPriceKR(dataForm.price) : ""}
                  </span>
                </div>
              </td>
              <th>정원</th>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("class_capacity")}
                    data-format="number"
                  />
                  <span className="unit hide_text">
                    강의실 정원 {scheduleCapacity()}명
                  </span>
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>수업료</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="full"
                    {...attributes("price")}
                    data-format="number"
                  />
                  <span className="unit hide_text">
                    {dataForm.price ? formatPriceKR(dataForm.price) : ""}
                  </span>
                </div>
              </td>
            </tr>
            <tr className="mobile">
              <th>정원</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="small"
                    {...attributes("class_capacity")}
                    data-format="number"
                  />
                  <span className="unit hide_text desktop">
                    강의실 정원 {scheduleCapacity()}명
                  </span>
                  <span className="unit hide_text mobile">
                    정원 {scheduleCapacity()}명
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn_group desktop">
          <button></button>
          <button
            className="btn_default half"
            onClick={submitConfirm}
            disabled={!submitCondition()}
          >
            수정
          </button>
        </div>
        <div className="btn_group mobile">
          <button
            className="btn_default"
            onClick={submitConfirm}
            disabled={!submitCondition()}
          >
            수정
          </button>
        </div>
      </section>
      <ModalComponent />
    </div>
  );
}
