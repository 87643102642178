import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useModalHandler from "hooks/useModalHandler";
import useRequestAPI from "hooks/useRequestAPI";
import formatDate from "utils/formatDate";
import formatPhoneNumber from "utils/formatPhoneNumber";
import ReturnToList from "components/ReturnToList";
import no_image from "assets/img/profile_noimage.png";
import ic_setting from "assets/img/icon/ic_setting.png";
import ic_file_off from "assets/img/icon/ic_file_off.png";
import permissionCheck from "utils/permissionCheck";

export default function AcademyTeachersDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { ModalComponent, openModal, sendModalData } = useModalHandler();
  const { requestAPI, Loading, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();

  const getData = async () => {
    const body = {
      mna_teacher_id: state?.mna_teacher_id,
      mna_manager_id: state?.mna_manager_id,
    };
    const res = await requestAPI("post", "academy/managers/detail", body);
    if (!res) return;
    setResData(res.data.data);
  };

  useEffect(() => {
    if (!permissionCheck(1)) {
      setMessage("권한이 없습니다.");
      return navigate(-1);
    }
    getData();
  }, [sendModalData]);

  return (
    <div className="inner teacher_detail">
      <Loading />

      <section className="content_wrap default_info">
        <header>
          <h2>기본 정보</h2>
          <div className="btn_group desktop">
            <ReturnToList />
          </div>
        </header>
        <section className="default_info_wrap">
          <section className="profile_img_wrap">
            <img src={resData?.info.profile_path || no_image} alt="" />
          </section>
          <section className="detail">
            <dl>
              <dt>이름</dt>
              <dd>{resData?.info.mna_name}</dd>
            </dl>
            <dl>
              <dt>성별</dt>
              <dd>{resData?.info.mna_gender}</dd>
            </dl>
            <dl>
              <dt>생년월일</dt>
              <dd>{formatDate(resData?.info.mna_birth, ".")}</dd>
            </dl>
            <dl>
              <dt>휴대폰 번호</dt>
              <dd>
                {resData && formatPhoneNumber(resData.info.mna_phone_number)}
              </dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>{resData?.info.mna_address}</dd>
            </dl>
            <dl>
              <dt>이메일</dt>
              <dd>{resData?.info.mna_email}</dd>
            </dl>
            <dl>
              <dt>수업</dt>
              <dd>
                <ul className="class_list">
                  {resData &&
                    JSON.parse(resData.info.mna_class_name).map((el, idx) => (
                      <li key={idx}>{el}</li>
                    ))}
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>입사일자</dt>
              <dd>{formatDate(resData?.info.mna_start_date, ".")}</dd>
              <dt className="desktop">근속기간</dt>
              <dd className="desktop">
                {resData?.info.mna_year > 0 && resData?.info.mna_year + "년"}{" "}
                {resData?.info.mna_month > 0 &&
                  resData?.info.mna_month + "개월"}{" "}
                {resData?.info.mna_day && resData?.info.mna_day + "일"}
              </dd>
            </dl>
            <dl className="mobile">
              <dt>근속기간</dt>
              <dd>
                {resData?.info.mna_year > 0 && resData?.info.mna_year + "년"}{" "}
                {resData?.info.mna_month > 0 &&
                  resData?.info.mna_month + "개월"}{" "}
                {resData?.info.mna_day && resData?.info.mna_day + "일"}
              </dd>
            </dl>
          </section>
        </section>
      </section>

      <section className="content_wrap salary_authority">
        <header>
          <h2>
            상태 및 급여정보
            <img
              src={ic_setting}
              alt=""
              onClick={() => {
                openModal("editSalaryInfo", "", {
                  auth: state,
                  info: resData?.info,
                });
              }}
            />
            <span className="desktop">{formatDate(new Date(), ".")} 기준</span>
            <span className="mobile">
              {formatDate(new Date(), ".").slice(2)} 기준
            </span>
          </h2>
        </header>
        <section className="detail">
          <dl>
            <dt>재직상태</dt>
            <dd>{resData?.info.mna_status}</dd>
          </dl>
          <dl>
            <dt>급여</dt>
            <dd>{resData?.info.mna_salary.toLocaleString()}원</dd>
          </dl>
          <dl>
            <dt>상여금</dt>
            <dd>{resData?.info.mna_bonus.toLocaleString()}원</dd>
          </dl>
        </section>
      </section>

      <section className="content_wrap ability">
        <section className="subject">
          <header>
            <h2>담당과목</h2>
          </header>
          {resData?.info_status.subject_view ? (
            <table className="fixed">
              <colgroup>
                <col width={"28%"} />
                <col width={"25%"} />
                <col width={"auto"} />
              </colgroup>

              {resData && !resData.subject[0] && (
                <tr>
                  <td colSpan={3}>등록된 담당과목이 없습니다.</td>
                </tr>
              )}
              {resData?.subject.map((el, idx) => {
                return (
                  <tr key={idx}>
                    <th>{el.subject_name}</th>
                    <td className="desktop">{el.subject_desc}</td>
                    <td className="level_list">
                      <p className="mobile mb_8">{el.subject_name}</p>
                      <ul>
                        {JSON.parse(el.subject_target)
                          .sort((a, b) => {
                            const orderArr = ["유아", "초등", "중등", "고등"];
                            return orderArr.indexOf(a) - orderArr.indexOf(b);
                          })
                          .map((el, idx) => {
                            return (
                              <li
                                key={idx}
                                className={"level_item target_" + (idx + 1)}
                              >
                                {el}
                              </li>
                            );
                          })}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            <p className="hidden_info">해당 정보는 '비공개' 처리 되었습니다.</p>
          )}
        </section>
        <section className="certificate">
          <header>
            <h2>자격증</h2>
          </header>
          {resData?.info_status.license_view ? (
            <table className="fixed">
              <colgroup>
                <col width={"28%"} />
                <col width={"25%"} className="desktop" />
                <col width={"40%"} className="mobile" />
              </colgroup>
              {resData && !resData.license[0] && (
                <tr>
                  <td colSpan={3}>등록된 자격증이 없습니다.</td>
                </tr>
              )}

              {resData?.license.map((el, idx) => {
                return (
                  <tr key={idx}>
                    <th>{el.license_name}</th>
                    <td>
                      <div className="certificate_date_wrap">
                        {el.license_date} 취득
                        <img src={ic_file_off} alt="" />
                      </div>
                    </td>
                    <td>
                      {el.license_is_certified && (
                        <span className="bf_auth_badge"></span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            <p className="hidden_info">해당 정보는 '비공개' 처리 되었습니다.</p>
          )}
        </section>
        <section className="history">
          <header>
            <h2>재직이력</h2>
          </header>
          {resData?.info_status.working_view ? (
            <table className="fixed">
              <colgroup>
                <col width={"28%"} />
                <col width={"25%"} className="desktop" />
                <col width={"40%"} className="mobile" />
                <col width={"20%"} className="mobile" />
              </colgroup>
              {resData && !resData.history[0] && (
                <tr>
                  <td colSpan={3}>등록된 재직이력이 없습니다.</td>
                </tr>
              )}

              {resData?.history.map((el, idx) => (
                <tr key={idx}>
                  <th className="hide_text">{el.history_academy_name}</th>
                  <td className="desktop">
                    {formatDate(el.history_start_date, ".")}~
                    {el.history_is_working
                      ? "재직중"
                      : formatDate(el.history_end_date, ".")}
                  </td>
                  <td className="mobile">
                    {formatDate(el.history_start_date, ".").slice(2)}~
                    {el.history_is_working
                      ? "재직중"
                      : formatDate(el.history_end_date, ".").slice(2)}
                  </td>
                  <td>
                    {el.history_is_cerificated && (
                      <span className="bf_auth_badge"></span>
                    )}
                    {el.history_is_academy_owner && (
                      <span className="biz_auth_badge"></span>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <p className="hidden_info">해당 정보는 '비공개' 처리 되었습니다.</p>
          )}
        </section>
      </section>

      <div className="btn_group mt_52 mb_32 desktop">
        <button></button>
        <button
          className="btn_default half"
          onClick={() => {
            openModal("changeTeacherGrade", "", {
              auth: state,
              info: resData?.info,
            });
          }}
        >
          권한변경
        </button>
      </div>
      <div className="btn_group mt_52 mobile">
        <button
          className="btn_default "
          onClick={() => {
            openModal("changeTeacherGrade", "", {
              auth: state,
              info: resData?.info,
            });
          }}
        >
          권한변경
        </button>
      </div>

      <ModalComponent />
    </div>
  );
}
