import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Messenger from "routes/messenger/Messenger";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import formatText from "utils/formatText";
import permissionCheck from "utils/permissionCheck";
import ViewFullImage from "components/modal/ViewFullImage";

export default function HomeworkDetail() {
  const navigate = useNavigate();
  const [isMessengerMode, setIsMessengerMode] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();
  const [isShowFullImage, setIsShowFullImage] = useState("hide");

  const getData = async () => {
    const res = await requestAPI("post", "notify/detail", {
      type: "homework",
      id,
    });
    if (!res) return;
    setResData({
      ...res.data.data,
      student_list: JSON.parse(res.data.data.student_list),
    });
  };

  const deleteData = () => {
    if (resData.is_completed)
      return setMessage("숙제를 완료한 학생이 있어 수정 및 삭제가 불가합니다.");
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "notify/delete", {
          type: "homework",
          id,
          mna_manager_id: state.mna_manager_id,
          class_id: resData.class_id,
        });
        if (!res) return;
        setMessage({
          text: alertMsg.delete,
          confirm: () => navigate("/homework/1"),
        });
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isMessengerMode) {
    return (
      <Messenger
        setIsMessengerMode={setIsMessengerMode}
        resData={{ ...resData, desc: resData.desc_text }}
      />
    );
  } else
    return (
      <div id="homework" className="container background_white">
        <div className="inner">
          <Loading />
          <header>
            <h2>숙제 상세</h2>
            <div className="btn_group short mobile">
              <button
                className="btn_default small fs_12"
                onClick={() => {
                  setIsMessengerMode({ state: true, mode: "view" });
                }}
              >
                메시지형태로 보기
              </button>
            </div>
            <div className="btn_group desktop">
              <button
                className="btn_default"
                onClick={() => {
                  setIsMessengerMode({ state: true, mode: "view" });
                }}
              >
                메시지형태로 보기
              </button>
              <ReturnToList />
            </div>
          </header>
          <section className="page_content_wrap mt_44">
            <table className="detail">
              <colgroup className="mobile">
                <col width={"30%"} />
              </colgroup>
              <tr>
                <th>대상</th>
                <td colSpan={3} className="target_list">
                  <ul>
                    {resData?.student_list
                      .filter((el) => !el.is_deleted)
                      .map((el) => {
                        return (
                          <li className={el.is_completed && "read"}>
                            {el.name}
                          </li>
                        );
                      })}
                  </ul>
                </td>
              </tr>
              <tr className="desktop">
                <th>마감일시</th>
                <td>{formatDate(resData?.due_date, ".")}</td>
                <th>포인트</th>
                <td>{resData?.reward.toLocaleString("ko-KR")}P</td>
              </tr>

              <tr className="mobile">
                <th>마감일시</th>
                <td>{formatDate(resData?.due_date, ".")}</td>
              </tr>
              <tr className="mobile">
                <th>포인트</th>
                <td>{resData?.reward.toLocaleString("ko-KR")}P</td>
              </tr>

              <tr>
                <th>첨부파일</th>
                <td colSpan={3}>
                  <ul className="attach_files">
                    {resData?.attach_files?.map((el, idx) => {
                      return (
                        <li>
                          <img
                            src={el.Location}
                            alt=""
                            onClick={() => {
                              setIsShowFullImage(idx);
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
              <tr>
                <th>내용</th>
                <td colSpan={3}>{formatText(resData?.desc_text)}</td>
              </tr>

              <tr className="mobile">
                <th>담당</th>
                <td>{resData?.teacher_name}</td>
              </tr>

              <tr className="mobile">
                <th>발송시간</th>
                <td>{formatDate(resData?.created_at, ".")}</td>
              </tr>

              <tr className="desktop">
                <th>담당</th>
                <td>{resData?.teacher_name}</td>
                <th>발송시간</th>
                <td>{formatDate(resData?.created_at, ".")}</td>
              </tr>
            </table>

            <div className="btn_group mt_44 desktop">
              <button
                className="btn_default half del permission"
                onClick={deleteData}
                disabled={!permissionCheck(1, state.mna_manager_id)}
              >
                삭제
              </button>

              <button
                className="btn_default half permission"
                onClick={() => {
                  if (resData.is_completed)
                    return setMessage(
                      "숙제를 완료한 학생이 있어 수정 및 삭제가 불가합니다."
                    );
                  navigate("/homework/edit/" + id, { state: { resData } });
                }}
                disabled={
                  !permissionCheck(1, state.mna_manager_id) ||
                  !resData ||
                  resData?.is_before
                }
              >
                수정
              </button>
            </div>
            <div className="btn_group mt_44 mobile">
              <button
                className="btn_default  del permission"
                onClick={deleteData}
                disabled={!permissionCheck(1, state.mna_manager_id)}
              >
                삭제
              </button>
              {!(
                !permissionCheck(1, state.mna_manager_id) ||
                !resData ||
                resData?.is_before
              ) && (
                <button
                  className="btn_default  permission"
                  onClick={() => {
                    if (resData.is_completed)
                      return setMessage(
                        "숙제를 완료한 학생이 있어 수정 및 삭제가 불가합니다."
                      );
                    navigate("/homework/edit/" + id, { state: { resData } });
                  }}
                >
                  수정
                </button>
              )}
            </div>
          </section>
        </div>
        {isShowFullImage !== "hide" &&
          resData?.attach_files?.map((el) => {
            return (
              <ViewFullImage
                src={el.Location}
                close={() => {
                  setIsShowFullImage("hide");
                }}
              />
            );
          })[isShowFullImage]}
      </div>
    );
}
