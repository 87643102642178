import useInputHandler from "hooks/useInputHandler";
import useDatePicker from "hooks/useDatePicker";
import useRequestAPI from "hooks/useRequestAPI";
import useFileHandler from "hooks/useFileHandler";
import formatDate from "utils/formatDate";
import ic_file_off from "assets/img/icon/ic_file_off.png";
import ic_file_on from "assets/img/icon/ic_file_on.png";

export default function LicenseDetail({ closeModal, changeData, currentData }) {
  const content = currentData.data?.content;
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();
  const [dataForm, , attributes] = useInputHandler({
    license_name: content ? content.license_name : "",
    license_no: content ? content.license_no : "",
    license_date: content ? content.license_date : "",
    license_path: content ? content.license_path : "",
  });
  const { DatePickerModule, specificDate } = useDatePicker({
    specific: dataForm.license_date && new Date(dataForm.license_date),
  });
  const { fileData, fileInputRef, uploadFile, uploadS3 } = useFileHandler();

  const submitData = async () => {
    if (!dataForm.license_path && !fileData[0])
      return setMessage("자격증 파일을 첨부해 주세요.");
    const uploader = localStorage.getItem("acadmey_id") ? "manager" : "teacher";
    const licenseData = fileData[0]
      ? await uploadS3(uploader)
      : dataForm.license_path
        ? dataForm.license_path
        : [];
    if (licenseData[0]?.Location) delete licenseData[0].Location;
    const body = {
      ...dataForm,
      license_date: formatDate(specificDate),
      license_path: licenseData,
    };

    const apiType = currentData.mode === "add" ? "put" : "patch";
    const apiName = currentData.mode === "add" ? "create" : "update";
    if (currentData.mode !== "add") {
      body.license_id = content.license_id;
    }
    const res = await requestAPI(apiType, "teacher/license/" + apiName, body);
    if (!res) return;
    setMessage({
      text: currentData.mode === "add" ? alertMsg.add : alertMsg.edit,
      confirm: () => changeData(res),
    });
  };

  const deleteData = () => {
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "teacher/license/delete", {
          license_id: content.license_id,
        });
        if (!res) return;
        setMessage({ text: alertMsg.delete, confirm: () => changeData(res) });
      },
    });
  };

  return (
    <aside id="addCertificate" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>자격증 {{ add: "추가", edit: "수정" }[currentData.mode]}</h2>
          <div className="btn_group">
            <button className="btn_default close" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        <table className="desktop">
          <colgroup>
            <col />
            <col width={"25%"} />
            <col />
          </colgroup>
          <thead>
            <th>자격증명</th>
            <th>자격증 번호</th>
            <th>취득일자</th>
            <th>증빙자료</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("license_name")}
                  />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("license_no")}
                  />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap">
                  <DatePickerModule
                    type="specific"
                    maxDate={"on"}
                    minDate={"off"}
                  />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap supporting_data">
                  <label htmlFor="file" className="file_ic_wrap">
                    <img
                      src={
                        dataForm.license_path || fileData[0]
                          ? ic_file_on
                          : ic_file_off
                      }
                      alt=""
                    />
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="file"
                      className="hidden"
                      onChange={uploadFile}
                    />
                  </label>
                  <span>
                    {dataForm.license_path || fileData[0] ? "저장" : "미저장"}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mobile">
          <tbody>
            <tr>
              <th>자격증명</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("license_name")} />
                </div>
              </td>
            </tr>

            <tr>
              <th>자격증 번호</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("license_no")} />
                </div>
              </td>
            </tr>

            <tr>
              <th>취득일자</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <DatePickerModule
                    type="specific"
                    maxDate={"on"}
                    minDate={"off"}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <th>증빙자료</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap supporting_data">
                  <label htmlFor="file" className="file_ic_wrap">
                    <img
                      src={
                        dataForm.license_path || fileData[0]
                          ? ic_file_on
                          : ic_file_off
                      }
                      alt=""
                    />
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="file"
                      className="hidden"
                      onChange={uploadFile}
                    />
                  </label>
                  <span>
                    {dataForm.license_path || fileData[0]
                      ? dataForm.license_path[0]?.name || fileData[0]?.name
                      : "첨부된 파일이 없습니다."}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {currentData.mode === "edit" && (
          <>
            <div className="btn_group desktop">
              <button className="btn_default half del" onClick={deleteData}>
                삭제
              </button>
              <button className="btn_default half" onClick={submitData}>
                수정
              </button>
            </div>
            <div className="btn_group mobile">
              <button className="btn_default  del" onClick={deleteData}>
                삭제
              </button>
              <button className="btn_default " onClick={submitData}>
                수정
              </button>
            </div>
          </>
        )}
        {currentData.mode === "add" && (
          <>
            <div className="btn_group desktop">
              <button></button>
              <button className="btn_default half" onClick={submitData}>
                추가
              </button>
            </div>
            <div className="btn_group mobile">
              <button></button>
              <button className="btn_default " onClick={submitData}>
                추가
              </button>
            </div>
          </>
        )}
      </div>
    </aside>
  );
}
