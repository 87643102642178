import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Messenger from "routes/messenger/Messenger";
import ReturnToList from "components/ReturnToList";
import useRequestAPI from "hooks/useRequestAPI";
import formatDate from "utils/formatDate";
import formatText from "utils/formatText";
import permissionCheck from "utils/permissionCheck";
import ViewFullImage from "components/modal/ViewFullImage";

export default function MaterialDetail() {
  const navigate = useNavigate();
  const [isMessengerMode, setIsMessengerMode] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();
  const [isShowFullImage, setIsShowFullImage] = useState("hide");

  const getData = async () => {
    const res = await requestAPI("post", "notify/detail", {
      type: "materials",
      id,
    });
    if (!res) return;
    setResData({
      ...res.data.data,
      student_list: JSON.parse(res.data.data.student_list),
    });
  };

  const deleteData = () => {
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "notify/delete", {
          type: "materials",
          id,
          mna_manager_id: state.mna_manager_id,
          class_id: resData.class_id,
        });
        if (!res) return;
        setMessage({
          text: alertMsg.delete,
          confirm: () => navigate("/material/1"),
        });
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isMessengerMode) {
    return (
      <Messenger
        setIsMessengerMode={setIsMessengerMode}
        resData={{ ...resData, desc: resData.desc_text }}
      />
    );
  } else
    return (
      <div id="material" className="container background_white">
        <div className="inner">
          <Loading />
          <header>
            <h2>준비물 상세</h2>
            <div className="btn_group short mobile">
              <button
                className="btn_default small fs_12"
                onClick={() => {
                  setIsMessengerMode({ state: true, mode: "view" });
                }}
              >
                메시지형태로 보기
              </button>
            </div>
            <div className="btn_group desktop">
              <button
                className="btn_default"
                onClick={() => {
                  setIsMessengerMode({ state: true, mode: "view" });
                }}
              >
                메시지형태로 보기
              </button>
              <ReturnToList />
            </div>
          </header>
          <section className="page_content_wrap mt_44">
            <table className="detail">
              <colgroup className="mobile">
                <col width={"30%"} />
              </colgroup>
              <tr>
                <th>대상</th>
                <td colSpan={3} className="target_list">
                  <ul>
                    {resData?.student_list
                      .filter((el) => !el.is_deleted)
                      .map((el) => {
                        return (
                          <li className={el.is_read && "read"}>{el.name}</li>
                        );
                      })}
                  </ul>
                </td>
              </tr>

              <tr className="desktop">
                <th>수업</th>
                <td>{resData?.class_name}</td>
                <th>일시</th>
                <td>
                  {resData?.class_datetime.map((el) => {
                    return (
                      <>
                        {el}
                        <br />
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr className="mobile">
                <th>수업</th>
                <td>{resData?.class_name}</td>
              </tr>
              <tr className="mobile">
                <th>일시</th>
                <td>
                  {resData?.class_datetime.map((el) => {
                    return (
                      <>
                        {el}
                        <br />
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr>
                <th>내용</th>
                <td colSpan={3}>{formatText(resData?.desc_text)}</td>
              </tr>
              <tr className="desktop">
                <th>담당</th>
                <td>{resData?.teacher_name}</td>
                <th>발송일시</th>
                <td>{formatDate(resData?.created_at, ".")}</td>
              </tr>

              <tr className="mobile">
                <th>담당</th>
                <td>{resData?.teacher_name}</td>
              </tr>
              <tr className="mobile">
                <th>발송일시</th>
                <td>{formatDate(resData?.created_at, ".")}</td>
              </tr>
            </table>

            <div className="btn_group mt_44 desktop">
              <button
                className="btn_default half del permission"
                onClick={deleteData}
                disabled={!permissionCheck(1, state.mna_manager_id)}
              >
                삭제
              </button>
              <button
                className="btn_default half permission"
                onClick={() => {
                  navigate("/material/edit/" + id, { state: { resData } });
                }}
                disabled={
                  !permissionCheck(1, state.mna_manager_id) ||
                  !resData ||
                  resData?.is_before ||
                  resData.send_type === 0
                }
              >
                수정
              </button>
            </div>
            <div className="btn_group mt_44 mobile">
              <button
                className="btn_default  del permission"
                onClick={deleteData}
                disabled={!permissionCheck(1, state.mna_manager_id)}
              >
                삭제
              </button>
              {!(
                !permissionCheck(1, state.mna_manager_id) ||
                !resData ||
                resData?.is_before ||
                resData.send_type === 0
              ) && (
                <button
                  className="btn_default  permission"
                  onClick={() => {
                    navigate("/material/edit/" + id, { state: { resData } });
                  }}
                >
                  수정
                </button>
              )}
            </div>
          </section>
        </div>
        {isShowFullImage !== "hide" &&
          resData?.attach_files?.map((el) => {
            return (
              <ViewFullImage
                src={el.Location}
                close={() => {
                  setIsShowFullImage("hide");
                }}
              />
            );
          })[isShowFullImage]}
      </div>
    );
}
