import { useEffect } from "react";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import { useNavigate } from "react-router-dom";

export default function AddReferrer({ closeModal, currentData }) {
  const [dataForm, setDataForm, attributes] = useInputHandler();
  const { requestAPI, alertMsg, Loading, setMessage } = useRequestAPI();

  useEffect(() => {}, []);

  const submitRefferer = async () => {
    if (!dataForm || !(!!dataForm.name && !!dataForm.mb_ph)) {
      return setMessage({
        text: "추천인 정보를 입력해주세요.",
      });
    }
    const body = {
      name: dataForm.name,
      phone_number: dataForm.mb_ph,
      academy_name: currentData.academy_name,
    };
    const res = await requestAPI("post", "academy/referrer-info", body);
    if (!res) return;
    setMessage({
      type: "complete",
      text: "추천인 등록을 완료했습니다.",
      confirm: () => {
        closeModal();
      },
    });
  };

  return (
    <aside id="addReferrer" className="modal dim">
      {/* <Loading /> */}
      <div className="modal_content_wrap">
        <header>
          <h2>학원 추천인 이벤트</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              건너뛰기
            </button>
          </div>
        </header>

        <section>
          <table>
            <tr>
              <td>
                <h3 className="mb_12">추천 학부모 성함</h3>
                <input
                  type="text"
                  className="small"
                  {...attributes("name")}
                  maxLength={20}
                />
              </td>
              <td>
                <h3 className="mb_12">추천인 휴대폰 번호</h3>
                <input
                  type="text"
                  className="half"
                  {...attributes("mb_ph")}
                  // placeholder="하이픈(-)을 포함하여 입력해 주세요."
                  data-format="number"
                />
              </td>
            </tr>
          </table>

          <div className="btn_group center mt_52">
            {/* <button className="btn_default half" onClick={closeModal}>
              건너뛰기
            </button> */}
            <button className="btn_default half" onClick={submitRefferer}>
              등록하기
            </button>
          </div>
        </section>
      </div>
    </aside>
  );
}
