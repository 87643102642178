import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatText from "utils/formatText";
import permissionCheck from "utils/permissionCheck";

export default function AcademyScheduleDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/class/detail", {
      class_id: id,
    });
    if (!res) return;
    setResData(res.data.data);
  };

  const submitData = () => {
    setMessage({
      type: "confirm",
      text: "수업 종료 시 수강 학생들과의 연결이 일괄 해제되며 재 운영이 불가능 합니다. 그래도 종료 하시겠습니까?",
      confirm: async () => {
        const body = {
          is_opening: false,
          class_id: id,
        };
        const res = await requestAPI("patch", "academy/class/open", body);
        if (!res) return;
        setMessage(alertMsg.end);
        getData();
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="inner schedule_add">
      <Loading />
      <header>
        <h2>시간표 상세({resData?.details.type})</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap schedule_add">
        <table className="detail">
          <colgroup>
            <col width={"160px"} />
            <col width={"36%"} />
            <col width={"160px"} />
          </colgroup>
          <tbody>
            <tr>
              <th>수업명</th>
              <td>
                <div className="cell_content_wrap">
                  {resData?.details.class_name}
                </div>
              </td>
              <th>과목</th>
              <td>
                <div className="cell_content_wrap">
                  {resData?.details.field_name}
                </div>
              </td>
            </tr>
            <tr>
              <th>시간</th>
              <td colSpan={3} className="select_list">
                <div className="cell_content_wrap">
                  {resData &&
                    JSON.parse(resData?.details.class_room_time).map(
                      (el, idx) => {
                        return (
                          <span key={idx} className="select_item">
                            {`${el.week || el.date} / ${el.start_time.slice(
                              0,
                              -3
                            )} - ${el.end_time.slice(0, -3)} / ${el.room}`}
                          </span>
                        );
                      }
                    )}
                </div>
              </td>
            </tr>
            {resData?.details.type === "일반" && (
              <tr>
                <th>기간</th>
                <td colSpan={3}>
                  <div className="cell_content_wrap">
                    {resData?.details.start_date.replace(/-/g, ".")} ~{" "}
                    {resData?.details.is_end_date
                      ? ""
                      : resData?.details.end_date.replace(/-/g, ".")}
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <th>담당</th>
              <td>
                <div className="cell_content_wrap">
                  {resData?.details.mna_teacher_name}
                </div>
              </td>

              <th>레벨</th>
              <td>
                <div className="cell_content_wrap">
                  {resData?.details.level}
                </div>
              </td>
            </tr>
            <tr>
              <th>수업내용</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  {formatText(resData?.details.memo)}
                </div>
              </td>
            </tr>
            <tr>
              <th>참여학생</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  {resData?.student_list
                    .map((el) => {
                      return el.name;
                    })
                    .join(", ")}
                </div>
              </td>
            </tr>
            <tr>
              <th>교재</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">{resData?.details.book}</div>
              </td>
            </tr>
            <tr>
              <th>부교재</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  {resData?.details.sub_book}
                </div>
              </td>
            </tr>
            <tr>
              <th>수업료</th>
              <td>
                <div className="cell_content_wrap">
                  <span className="unit">
                    {resData?.details.price.toLocaleString("ko-KR")}원
                  </span>
                </div>
              </td>
              <th>정원</th>
              <td>
                <div className="cell_content_wrap">
                  <span className="unit">
                    {resData?.details.class_capacity}명
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {resData?.details.class_is_opening ? (
          <div className="btn_group mt_52 mb_52">
            <button
              className="btn_default half del permission"
              onClick={submitData}
              disabled={!permissionCheck(1)}
            >
              종료
            </button>
            <button
              className="btn_default half permission"
              onClick={() => {
                navigate("/academy/schedule/edit/" + id, {
                  state: { resData },
                });
              }}
              disabled={!permissionCheck(1)}
            >
              수정
            </button>
          </div>
        ) : (
          resData && <p className="bottom_desc mt_20">※종료된 수업입니다.</p>
        )}
      </section>
    </div>
  );
}
