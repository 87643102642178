import useCategoryList from "hooks/useCategoryList";
import useInputHandler from "hooks/useInputHandler";
import { useEffect } from "react";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";

export default function SubjectDetail({ closeModal, changeData, currentData }) {
  const content = currentData.data?.content;
  const [CategoryList, selected, setSelected] = useCategoryList("target");
  const [dataForm, , attributes] = useInputHandler({
    desc: content ? content.subject_name : "",
  });
  const { requestAPI, alertMsg, setMessage, Loading } = useRequestAPI();
  const [SelectBox, selectBoxValue, setSelectBoxValue, setSelectList] =
    useSelectBox([]);

  const getData = async () => {
    const res = await requestAPI("post", "teacher/subject", {});
    const data = res.data.data.fieldList;
    setSelectList(data);
    if (currentData.mode === "add") {
      setSelectBoxValue(data[0]);
    } else {
      setSelected(content.target);
      setSelectBoxValue(data.find((el) => el.name === content.field_name));
    }
  };

  const submitData = async () => {
    const body = {
      field: selectBoxValue.id,
      desc: dataForm.desc,
      targets: selected,
    };
    if (!selected[0]) return setMessage("과목 대상 등급을 선택해주세요.");
    if (currentData.mode !== "add") {
      body.subject_id = content.subject_id;
    }
    const apiType = currentData.mode === "add" ? "put" : "patch";
    const apiName = currentData.mode === "add" ? "create" : "update ";
    const res = await requestAPI(apiType, "teacher/subject/" + apiName, body);
    if (!res) return;
    setMessage({ text: alertMsg.add, confirm: () => changeData(res) });
  };

  const deleteData = async () => {
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "teacher/subject/delete", {
          subject_id: content.subject_id,
        });
        if (!res) return;
        setMessage({ text: alertMsg.delete, confirm: () => changeData(res) });
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <aside id="addSubjectDetail" className="modal dim">
      <Loading />
      <div className="modal_content_wrap">
        <header>
          <h2>담당과목 {{ add: "추가", edit: "수정" }[currentData.mode]}</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        <table className="fixed desktop">
          <colgroup>
            <col width={"auto"} />
            <col width={"auto"} />
            <col width={"364px"} />
          </colgroup>
          <thead>
            <tr>
              <th>카테고리</th>
              <th>상세내용</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap">
                  <input type="text" className="half" {...attributes("desc")} />
                </div>
              </td>
              <td className="category_list">
                <div className="cell_content_wrap">
                  <CategoryList />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="fixed mobile">
          <tbody>
            <tr>
              <th>카테고리</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox className="full" />
                </div>
              </td>
            </tr>
            <tr>
              <th>상세내용</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input type="text" className="" {...attributes("desc")} />
                </div>
              </td>
            </tr>
            <tr>
              <th>수업대상</th>
            </tr>
            <tr>
              <td className="category_list">
                <div className="cell_content_wrap">
                  <CategoryList />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {currentData.mode === "edit" && (
          <>
            <div className="btn_group desktop">
              <button className="btn_default half del" onClick={deleteData}>
                삭제
              </button>
              <button className="btn_default half" onClick={submitData}>
                수정
              </button>
            </div>
            <div className="btn_group mobile">
              <button className="btn_default  del" onClick={deleteData}>
                삭제
              </button>
              <button className="btn_default " onClick={submitData}>
                수정
              </button>
            </div>
          </>
        )}
        {currentData.mode === "add" && (
          <>
            <div className="btn_group desktop">
              <button></button>
              <button className="btn_default half" onClick={submitData}>
                추가
              </button>
            </div>
            <div className="btn_group mobile">
              <button></button>
              <button className="btn_default " onClick={submitData}>
                추가
              </button>
            </div>
          </>
        )}
      </div>
    </aside>
  );
}
