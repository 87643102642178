import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Messenger from "routes/messenger/Messenger";
import useInputHandler from "hooks/useInputHandler";
import useStudentCheckBox from "hooks/useStudentCheckBox";
import useFileHandler from "hooks/useFileHandler";
import useDatePicker from "hooks/useDatePicker";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";

export default function HomeworkAdd() {
  const navigate = useNavigate();
  const [dataForm, , attributes] = useInputHandler({
    reward: 50,
  });
  const { listData, StudentList, SelectList, submitDataFormat } =
    useStudentCheckBox();
  const { fileData, fileInputRef, uploadFile, deleteFile, uploadS3 } =
    useFileHandler(["jpg", "jpeg", "png", "gif"], 1, 3);
  const { DatePickerModule, specificDate } = useDatePicker();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [isMessengerMode, setIsMessengerMode] = useState({
    state: false,
    mode: "",
  });

  const submitConditionCheck = () => {
    if (!submitDataFormat[0]) {
      setMessage("대상을 선택해주세요.");
      return false;
    }
    if (50 > dataForm.reward || dataForm.reward > 100) {
      setMessage("포인트는 50P~100P 까지 지급 가능합니다.");
      return false;
    }
    if (!dataForm.desc && !fileData[0]) {
      setMessage(alertMsg.null_contetn_file);
      return false;
    }
    const selectClassId = submitDataFormat.map((el) => Number(el.class_id));
    let checkDueDate = listData
      .filter((el) => selectClassId.includes(el.class_id))
      .map((el) => {
        return el.class_times.map((el2) => el2.week);
      })
      .flat();
    checkDueDate = [...new Set(checkDueDate)];
    const days = ["일", "월", "화", "수", "목", "금", "토"];

    if (!checkDueDate.includes(days[specificDate.getDay()])) {
      setMessage({
        type: "confirm",
        text: "숙제 마감일의 요일이 선택된 수업들의 수업 요일에 해당되지 않습니다. 그래도 등록하시겠습니까?",
        confirm: () => setIsMessengerMode({ state: true, mode: "submit" }),
      });
    } else setIsMessengerMode({ state: true, mode: "submit" });
  };

  const submitData = async () => {
    const homeworkFileData = fileData[0] ? await uploadS3("manager") : [];
    const body = {
      ...dataForm,
      mna_manager_id: localStorage.getItem("manager_id"),
      type: "homework",
      notification_list: submitDataFormat,
      file_list: homeworkFileData,
      due_date: formatDate(specificDate),
      desc: `[숙제]%&${dataForm.desc.replace(
        /\r?\n/g,
        "%&"
      )}%&기한 : ${formatDate(specificDate)} 까지`,
      desc_text: dataForm.desc.replace(/\r?\n/g, "%&"),
    };
    const res = await requestAPI("put", "notify/create", body);
    if (!res) return;
    setMessage({ text: alertMsg.add, confirm: () => navigate("/homework/1") });
  };

  return (
    <>
      <Loading />
      {isMessengerMode.state ? (
        <Messenger
          mode={isMessengerMode.mode}
          setIsMessengerMode={setIsMessengerMode}
          resData={{
            student_list: submitDataFormat.map((el) => el.student_list).flat(1),
            attach_files: fileData[0] ? fileData : null,
            desc: dataForm.desc,
            due_date: formatDate(specificDate, "."),
          }}
          submitData={submitData}
        />
      ) : (
        <div id="homework" className="container background_white">
          <div className="inner">
            <header>
              <h2>숙제 등록</h2>
              <div className="btn_group short mobile">
                <button
                  className="btn_default small fs_12"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
              </div>
              <div className="btn_group desktop">
                <button
                  className="btn_default"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
                <ReturnToList />
              </div>
            </header>
            <div className="page_content_wrap">
              <section className="input_main_wrap">
                <div className="input_select_wrap">
                  <h3>학생목록</h3>
                  <StudentList />
                </div>
                <div className="input_select_wrap">
                  <h3>대상</h3>
                  <SelectList />
                </div>
                <div className="input_select_wrap content">
                  <h3>내용</h3>
                  <div className="input_select_item">
                    <textarea
                      {...attributes("desc")}
                      placeholder="내용을 입력해주세요."
                    ></textarea>
                  </div>
                </div>
              </section>

              <section className="input_sub_wrap">
                <table className="detail">
                  <tr className="mobile">
                    <th>마감일</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <DatePickerModule type="specific" />
                      </div>
                    </td>
                  </tr>
                  <tr className="mobile">
                    <th>포인트</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <div>
                          <input
                            type="text"
                            className={
                              "small " + (dataForm.reward < 50 && "error")
                            }
                            {...attributes("reward")}
                            data-format="number"
                          />{" "}
                          P
                        </div>
                        <span className="fs_12 mt_8">※50~100 사이</span>
                      </div>
                    </td>
                  </tr>

                  <tr className="desktop">
                    <th>마감일</th>
                    <td>
                      <div className="cell_content_wrap">
                        <DatePickerModule type="specific" />
                      </div>
                    </td>
                    <th>포인트</th>
                    <td>
                      <div className="cell_content_wrap">
                        <input
                          type="text"
                          className={
                            "small " + (dataForm.reward < 50 && "error")
                          }
                          {...attributes("reward")}
                          data-format="number"
                        />
                        P<span className="fs_16">※50~100 사이</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <div className="btn_group">
                          <button
                            htmlFor="file"
                            className="btn_default slim green3"
                          >
                            <label htmlFor="file">파일선택</label>
                          </button>
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                            id="file"
                            onChange={uploadFile}
                            multiple
                          />
                        </div>
                        <ul className="file_list">
                          {fileData[0] &&
                            fileData.map((el) => {
                              return (
                                <li>
                                  <span>{el.name}</span>{" "}
                                  <button onClick={deleteFile}>x</button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>메모</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <input type="text" {...attributes("memo")} />
                      </div>
                    </td>
                  </tr>
                </table>
              </section>

              <div className="btn_group desktop">
                <button></button>
                <button
                  className="btn_default half"
                  onClick={submitConditionCheck}
                >
                  발송
                </button>
              </div>
              <div className="btn_group mobile">
                <button className="btn_default " onClick={submitConditionCheck}>
                  발송
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
