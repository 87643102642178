import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useModalHandler from "hooks/useModalHandler";
import useDatePicker from "hooks/useDatePicker";
import useFindPostAddr from "hooks/useFindPostAddr";
import useInputHandler from "hooks/useInputHandler";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import useValidation from "hooks/useValidation";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function AcademyShuttleAdd() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ModalComponent, openModal, sendModalData, currentModal } =
    useModalHandler();
  const { DatePickerModule, startDate, endDate, setEndDate } = useDatePicker();
  const { DaumPost, userAddr, openDaumPost, position } = useFindPostAddr(true);
  const [dataForm, setDataForm, attributes] = useInputHandler({
    schedule_type: id === "reg" ? "0" : "1",
    bus_name: "",
    bus_no: "",
    driver_name: "",
    driver_phone_number: "",
    departure_time: "05:00",
    arrival_time: "23:59",
    operation_date: [],
    bus_stop_list: [],
    start_date: "",
    end_date: "",
    pic: "",
    memo: "",
    manager_name: "",
    bus_driver_id: "0", // 임시로 무조건 0, 백엔드에서 필요한 값이라 보냄
  });
  const { form, validPass, errorCheck, validAttributes } = useValidation();
  const [isDupTeacher, setIsDupTeacher] = useState(false);

  const [stationDataForm, setStationDataForm, stationAttributes] =
    useInputHandler({
      station: "",
      address: "",
      location: "",
      boarding_time: "",
    });
  const [SelectBox, selectValue, setSelectValue] = useSelectBox([
    { name: "등원", code: "0" },
    { name: "하원", code: "1" },
  ]);
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [isLoadedData, setIsLoadedData] = useState(false);

  const boardingTimeCheck = (target) => {
    return (
      target.boarding_time.replace(":", "") >
        dataForm.arrival_time.replace(":", "") ||
      target.boarding_time.replace(":", "") <
        dataForm.departure_time.replace(":", "")
    );
  };

  const submitData = async () => {
    let { memo, ...etc } = dataForm;
    etc = {
      ...etc,
      bus_no: form.bus_no.val,
      driver_name: form.mb_name.val,
      driver_phone_number: form.mb_ph.val,
    };
    if (id === "reg") {
      delete etc.start_date;
      delete etc.end_date;
    } else {
      etc.start_date = formatDate(startDate, "-");
      etc.end_date = formatDate(endDate, "-");
    }
    if (
      !Object.values(etc).every((el) => {
        return el[0];
      })
    ) {
      return setMessage(alertMsg.all_fields);
    }
    if (!validPass(["mb_name", "mb_ph", "bus_no"])) {
      return setMessage("입력값의 형식을 다시 확인해주세요.");
    }
    if (
      etc.bus_stop_list.some((el) => {
        return boardingTimeCheck(el);
      })
    ) {
      return setMessage(
        "정류장 탑승시간은 출발시간과 도착시간을 벗어날 수 없습니다."
      );
    }
    if (isDupTeacher) {
      return setMessage(
        "담당 선생님 일정이 중복되었습니다.\n 다른 선생님으로 다시 선택해주세요."
      );
    }
    const res = await requestAPI("put", "academy/shuttle-bus/create", {
      memo,
      ...etc,
      direction: selectValue.code,
    });
    if (!res) return;
    setMessage({
      text: alertMsg.add,
      confirm: () => navigate("/academy/shuttle/1"),
    });
  };

  const sortStation = (arr) => {
    arr.sort((a, b) => {
      return (
        a.boarding_time.replace(":", "") - b.boarding_time.replace(":", "")
      );
    });
    return arr.map((el, index) => {
      return { ...el, index };
    });
  };

  const addStation = async () => {
    if (!Object.values(stationDataForm).every((el) => el))
      return setMessage(alertMsg.all_fields);
    if (
      stationDataForm.boarding_time.replace(":", "") >
      dataForm.arrival_time.replace(":", "")
    ) {
      return setMessage("탑승시간은 도착시간을 초과할 수 없습니다.");
    }
    if (
      stationDataForm.boarding_time.replace(":", "") <
      dataForm.departure_time.replace(":", "")
    ) {
      return setMessage("탑승시간은 출발시간 이전일 수 없습니다.");
    }
    let copy = [...dataForm.bus_stop_list];
    const { latitude, longitude } = position;
    copy.push({ ...stationDataForm, latitude, longitude });
    copy = sortStation(copy);
    setDataForm({ ...dataForm, bus_stop_list: copy });
    setStationDataForm({
      station: "",
      address: "",
      location: "",
      boarding_time: "",
    });
  };

  const deleteStation = (e) => {
    const idx = e.target.dataset.idx;
    let copy = { ...dataForm };
    copy.bus_stop_list.splice(idx, 1);
    copy.bus_stop_list = copy.bus_stop_list.map((el, index) => {
      return { ...el, index };
    });
    setDataForm(copy);
  };

  const applyOneDay = (e) => {
    if (e.target.checked) {
      setEndDate(new Date(startDate));
    }
  };

  // console.log(dataForm);
  const teacherAvailability = async () => {
    if (!dataForm.pic) return;
    const dayFormat = ["월", "화", "수", "목", "금", "토", "일"];
    let week = dataForm.operation_date;
    week = week.map((el) => dayFormat[el]);
    week = week.join();
    const timeData = {
      week,
      start_time: dataForm.departure_time,
      end_time: dataForm.arrival_time,
    };
    const res = await requestAPI("post", "academy/class/manager", {
      class_room_time: [timeData],
      mna_manager_id: dataForm?.pic,
    });
    if (!res) return;
    if (res.data.data.class_time.is_valid) setIsDupTeacher(true);
    else setIsDupTeacher(false);
  };

  useEffect(() => {
    teacherAvailability();
  }, [dataForm.departure_time, dataForm.arrival_time, dataForm.operation_date]);

  useEffect(() => {
    setStationDataForm({
      ...stationDataForm,
      address: userAddr.addr,
    });
  }, [userAddr]);

  useEffect(() => {
    switch (currentModal.type) {
      case "selectTeacher": {
        return setDataForm({
          ...dataForm,
          manager_name: sendModalData.name,
          pic: sendModalData.id.toString(),
        });
      }
      case "selectShuttle": {
        const { id, manager_id, ...etc } = sendModalData;
        setIsLoadedData(true);
        setSelectValue({ code: etc.direction, name: "" });
        return setDataForm({
          ...etc,
          pic: manager_id.toString(),
          schedule_type: "1",
        });
      }
      default: {
        return;
      }
    }
  }, [sendModalData]);

  return (
    <div className="inner shuttle_add">
      <Loading />
      <ModalComponent />
      <DaumPost />
      <header>
        <h2>셔틀버스 등록({{ reg: "정기", temp: "임시" }[id]})</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        {id === "temp" && (
          <div className="btn_group mb_24 mt_0">
            <button
              className="btn_default half slim"
              onClick={() => {
                openModal("selectShuttle");
              }}
            >
              기존 정보 불러오기
            </button>
          </div>
        )}
        <table className="detail desktop">
          <tr>
            <th>버스이름</th>
            <td colSpan={2}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("bus_name")}
                  readOnly={isLoadedData}
                />
              </div>
            </td>
            <th>차량번호</th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("bus_no")?.state}
                  {...validAttributes("bus_no")}
                  maxLength={9}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>운전자명</th>
            <td colSpan={2}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </div>
            </td>
            <th>
              운전자
              <br />
              전화번호
            </th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_ph")?.state}
                  {...validAttributes("mb_ph")}
                  data-format="number"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th rowSpan={3}>운행정보</th>
            <td width={"120px"}>종류</td>
            <td className="shuttle_type">
              <div className="cell_content_wrap">
                {isLoadedData ? (
                  dataForm?.direction === "0" ? (
                    "등원"
                  ) : (
                    "하원"
                  )
                ) : (
                  <SelectBox />
                )}
              </div>
            </td>
            <th rowSpan={3}>운행요일</th>
            {!isLoadedData && (
              <td rowSpan={3}>
                <div className="cell_content_wrap">
                  <ul className="operating_days">
                    {["월", "화", "수", "목", "금", "토", "일"].map(
                      (el, idx) => (
                        <li>
                          <input
                            type="checkbox"
                            className="mr_4"
                            id={"day_" + idx}
                            {...attributes("operation_date", idx)}
                            checked={dataForm.operation_date.includes(
                              idx.toString()
                            )}
                          />
                          <label htmlFor={"day_" + idx}>{el}</label>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </td>
            )}
            {isLoadedData && (
              <td rowSpan={2}>
                <div className="cell_content_wrap">
                  {dataForm?.operation_date
                    .sort((a, b) => a - b)
                    .map((el) => {
                      return ["월", "화", "수", "목", "금", "토", "일"][el];
                    })
                    .join(", ")}
                </div>
              </td>
            )}
          </tr>
          <tr>
            <td>출발시간</td>
            <td className="start_time">
              <div className="cell_content_wrap">
                {isLoadedData ? (
                  dataForm?.departure_time.slice(0, -3)
                ) : (
                  <input
                    type="time"
                    {...attributes("departure_time")}
                    readOnly={isLoadedData}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>도착시간</td>
            <td className="start_time">
              <div className="cell_content_wrap">
                {isLoadedData ? (
                  dataForm?.arrival_time.slice(0, -3)
                ) : (
                  <input
                    type="time"
                    {...attributes("arrival_time")}
                    readOnly={isLoadedData}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <th>정류장 정보</th>
            <td colSpan={4} className="station_list">
              <div className="cell_content_wrap">
                <table className={"detail " + (isLoadedData && "border_none")}>
                  {isLoadedData ? (
                    <colgroup>
                      <col width="15%" />
                      <col width="40%" />
                      <col width="auto" />
                      <col width="auto" />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col width="auto" />
                      <col width="30%" />
                      <col width="auto" />
                      <col width="auto" />
                      <col width="auto" />
                    </colgroup>
                  )}
                  <thead>
                    <tr>
                      <th>명칭</th>
                      <th>주소 (클릭하여 조회)</th>
                      <th>상세위치</th>
                      <th>탑승시간</th>
                      {!isLoadedData && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadedData && (
                      <tr className="shuttle_option">
                        <td>
                          <input
                            type="text"
                            value={stationDataForm.name}
                            {...stationAttributes("station")}
                          />
                        </td>
                        <td>
                          <input
                            className="pointer"
                            type="text"
                            placeholder="장소선택"
                            value={stationDataForm.address}
                            onClick={openDaumPost}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={stationDataForm.detailAddr}
                            {...stationAttributes("location")}
                          />
                        </td>
                        <td>
                          <input
                            type="time"
                            value={stationDataForm.time}
                            {...stationAttributes("boarding_time")}
                          />
                        </td>
                        <td>
                          <div className="btn_group">
                            <button
                              className="btn_default slim green3"
                              onClick={addStation}
                            >
                              추가
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}

                    {dataForm?.bus_stop_list.map((el, idx) => (
                      <tr className="station_item" key={idx}>
                        <td className="hide_text">{el.station}</td>
                        <td className="hide_text">{el.address}</td>
                        <td className="hide_text">{el.location}</td>
                        <td>
                          <input
                            className={boardingTimeCheck(el) ? "wrong" : ""}
                            type="time"
                            value={el.boarding_time}
                            onChange={(e) => {
                              if (
                                e.target.value.replace(":", "") >
                                dataForm.arrival_time.replace(":", "")
                              ) {
                                return setMessage(
                                  "도착시간을 초과할 수 없습니다."
                                );
                              }
                              if (
                                e.target.value.replace(":", "") <
                                dataForm.departure_time.replace(":", "")
                              ) {
                                return setMessage(
                                  "출발시간보다 이전일 수 없습니다."
                                );
                              }
                              let copy = { ...dataForm };
                              copy.bus_stop_list[idx].boarding_time =
                                e.target.value;
                              copy.bus_stop_list = sortStation(
                                copy.bus_stop_list
                              );
                              setDataForm(copy);
                            }}
                            readOnly={isLoadedData}
                          />
                        </td>
                        {!isLoadedData && (
                          <td>
                            <div className="btn_group">
                              <button
                                className="btn_default slim del"
                                data-idx={idx}
                                onClick={deleteStation}
                              >
                                삭제
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <th>담당 선생님</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {dataForm.teacher}
                <input
                  className={"teacher" + (isDupTeacher ? " error" : "")}
                  type="text"
                  value={
                    dataForm?.manager_name + (isDupTeacher ? " (시간중복)" : "")
                  }
                  readOnly
                />
                <div className="btn_group short">
                  <button
                    className="btn_default  slim green3"
                    onClick={() =>
                      openModal("selectTeacher", "add", {
                        class_room_time: [
                          {
                            week: dataForm.operation_date.join(","),
                            start_time: dataForm.departure_time,
                            end_time: dataForm.arrival_time,
                          },
                        ],
                      })
                    }
                    disabled={!dataForm.operation_date[0]}
                  >
                    선택
                  </button>
                </div>
              </div>
            </td>
          </tr>
          {id === "temp" && (
            <tr>
              <th>적용일정</th>
              <td colSpan={4}>
                <div className="cell_content_wrap">
                  <DatePickerModule type="start" />~
                  <DatePickerModule type="end" />
                  <label htmlFor="">
                    <input
                      type="checkbox"
                      onClick={applyOneDay}
                      checked={startDate.getTime() === endDate.getTime()}
                    />{" "}
                    하루적용
                  </label>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <th>메모</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <textarea {...attributes("memo")}></textarea>
              </div>
            </td>
          </tr>
        </table>

        <table className="detail mobile fixed">
          <tr>
            <th>버스이름</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("bus_name")}
                  readOnly={isLoadedData}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>차량번호</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("bus_no")?.state}
                  {...validAttributes("bus_no")}
                  maxLength={9}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>운전자명</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              운전자
              <br />
              전화번호
            </th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_ph")?.state}
                  {...validAttributes("mb_ph")}
                  data-format="number"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th rowSpan={3}>운행정보</th>
            <td>종류</td>
            <td colSpan={3} className="shuttle_type">
              <div className="cell_content_wrap">
                {isLoadedData ? (
                  dataForm?.direction === "0" ? (
                    "등원"
                  ) : (
                    "하원"
                  )
                ) : (
                  <SelectBox />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              출발
              <br />
              시간
            </td>
            <td colSpan={3} className="start_time">
              <div className="cell_content_wrap">
                {isLoadedData ? (
                  dataForm?.departure_time.slice(0, -3)
                ) : (
                  <input
                    type="time"
                    {...attributes("departure_time")}
                    readOnly={isLoadedData}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              도착
              <br />
              시간
            </td>
            <td colSpan={3} className="start_time">
              <div className="cell_content_wrap">
                {isLoadedData ? (
                  dataForm?.arrival_time.slice(0, -3)
                ) : (
                  <input
                    type="time"
                    {...attributes("arrival_time")}
                    readOnly={isLoadedData}
                  />
                )}
              </div>
            </td>
          </tr>
          <tr>
            <th>운행요일</th>
            {!isLoadedData && (
              <td colSpan={4}>
                <div className="cell_content_wrap pt_12 pb_12">
                  <ul className="operating_days">
                    {["월", "화", "수", "목", "금", "토", "일"].map(
                      (el, idx) => (
                        <li>
                          <input
                            type="checkbox"
                            className="mr_4"
                            id={"day_" + idx}
                            {...attributes("operation_date", idx)}
                            checked={dataForm.operation_date.includes(
                              idx.toString()
                            )}
                          />
                          <label htmlFor={"day_" + idx}>{el}</label>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </td>
            )}
            {isLoadedData && (
              <td colSpan={4}>
                <div className="cell_content_wrap">
                  {dataForm?.operation_date
                    .sort((a, b) => a - b)
                    .map((el) => {
                      return ["월", "화", "수", "목", "금", "토", "일"][el];
                    })
                    .join(", ")}
                </div>
              </td>
            )}
          </tr>
          <tr>
            <td colSpan={5} className="station_list" style={{ border: "none" }}>
              <div className="flex jc_sp_bw ai_st">
                <Swiper
                  // className="mySwiper"
                  pagination={true}
                  modules={[Pagination]}
                >
                  {dataForm?.bus_stop_list.map((el, idx) => (
                    <SwiperSlide>
                      <div key={idx} className="table_wrap">
                        <table className={"detail "}>
                          <colgroup>
                            <col width={"25%"} />
                            <col width={""} />
                          </colgroup>
                          <tr>
                            <th colSpan={2} className="title">
                              <div className="flex jc_sp_bw">
                                <strong>정류장 정보</strong>
                                <div className="btn_group short">
                                  <button
                                    className="btn_default del slim"
                                    data-idx={idx}
                                    onClick={deleteStation}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th>명칭</th>
                            <td>{el.station}</td>
                          </tr>

                          <tr>
                            <th>주소</th>
                            <td>{el.address}</td>
                          </tr>

                          <tr>
                            <th>상세위치</th>
                            <td>{el.location}</td>
                          </tr>

                          <tr>
                            <th>탑승시간</th>
                            <td>{el.boarding_time}</td>
                          </tr>
                        </table>
                      </div>
                    </SwiperSlide>
                  ))}
                  <SwiperSlide>
                    <div className="table_wrap">
                      <table className={`detail `}>
                        <colgroup>
                          <col width={"25%"} />
                          <col width={""} />
                        </colgroup>

                        <tr>
                          <th colSpan={2} className="title">
                            <div className="flex jc_sp_bw">
                              <strong>정류장 정보</strong>
                              <div className="btn_group short">
                                <button
                                  className="btn_default slim"
                                  onClick={addStation}
                                >
                                  추가
                                </button>
                              </div>
                            </div>
                          </th>
                        </tr>

                        <tr>
                          <th>명칭</th>
                          <td>
                            <input
                              type="text"
                              value={stationDataForm.name}
                              {...stationAttributes("station")}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>주소</th>
                          <td>
                            <input
                              className="pointer"
                              type="text"
                              placeholder="클릭하여 조회"
                              value={stationDataForm.address}
                              onClick={openDaumPost}
                              readOnly
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>상세위치</th>
                          <td>
                            <input
                              type="text"
                              value={stationDataForm.detailAddr}
                              {...stationAttributes("location")}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>탑승시간</th>
                          <td>
                            <input
                              type="time"
                              value={stationDataForm.time}
                              {...stationAttributes("boarding_time")}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </td>
          </tr>
          <tr>
            <th>담당 선생님</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {dataForm.teacher}
                <input
                  className={"teacher" + (isDupTeacher ? " error" : "")}
                  type="text"
                  value={
                    dataForm?.manager_name + (isDupTeacher ? " (시간중복)" : "")
                  }
                  readOnly
                />
                <div className="btn_group">
                  <button
                    className="btn_default  slim green3"
                    onClick={() =>
                      openModal("selectTeacher", "add", {
                        class_room_time: [
                          {
                            week: dataForm.operation_date.join(","),
                            start_time: dataForm.departure_time,
                            end_time: dataForm.arrival_time,
                          },
                        ],
                      })
                    }
                    disabled={!dataForm.operation_date[0]}
                  >
                    선택
                  </button>
                </div>
              </div>
            </td>
          </tr>
          {id === "temp" && (
            <tr>
              <th>적용일정</th>
              <td colSpan={4}>
                <div className="cell_content_wrap date">
                  <DatePickerModule type="start" />~
                  <DatePickerModule type="end" />
                  <label htmlFor="">
                    <input
                      type="checkbox"
                      onClick={applyOneDay}
                      checked={startDate.getTime() === endDate.getTime()}
                    />{" "}
                    하루적용
                  </label>
                </div>
              </td>
            </tr>
          )}
          <tr>
            <th>메모</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <textarea {...attributes("memo")}></textarea>
              </div>
            </td>
          </tr>
        </table>

        <div className="btn_group desktop">
          <button></button>
          <button className="btn_default half" onClick={submitData}>
            등록
          </button>
        </div>
        <div className="btn_group mobile">
          <button className="btn_default " onClick={submitData}>
            등록
          </button>
        </div>
      </section>
    </div>
  );
}
