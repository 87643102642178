import { useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import permissionCheck from "utils/permissionCheck";
import ic_side_academy from "assets/img/icon/ic_side_academy.png";
import ic_side_academy_active from "assets/img/icon/ic_side_academy_active.png";
import ic_side_teacher from "assets/img/icon/ic_side_teacher.png";
import ic_side_teacher_active from "assets/img/icon/ic_side_teacher_active.png";
import ic_side_classroom from "assets/img/icon/ic_side_classroom.png";
import ic_side_classroom_active from "assets/img/icon/ic_side_classroom_active.png";
import ic_side_schedule from "assets/img/icon/ic_side_schedule.png";
import ic_side_schedule_active from "assets/img/icon/ic_side_schedule_active.png";
import ic_side_bus from "assets/img/icon/ic_side_bus.png";
import ic_side_bus_active from "assets/img/icon/ic_side_bus_active.png";
import ic_side_evaluation from "assets/img/icon/ic_side_evaluation.png";
import ic_side_evaluation_active from "assets/img/icon/ic_side_evaluation_active.png";
import ic_side_end from "assets/img/icon/ic_side_end.png";
import ic_side_end_active from "assets/img/icon/ic_side_end_active.png";
import ic_side_pay from "assets/img/icon/ic_side_pay.png";
import ic_side_pay_active from "assets/img/icon/ic_side_pay_active.png";
import ic_side_arrow from "assets/img/icon/ic_side_arrow.png";

const icons = [
  ic_side_academy,
  ic_side_teacher,
  ic_side_classroom,
  ic_side_schedule,
  ic_side_bus,
  ic_side_evaluation,
  ic_side_end,
  ic_side_pay,
];

const iconsActive = [
  ic_side_academy_active,
  ic_side_teacher_active,
  ic_side_classroom_active,
  ic_side_schedule_active,
  ic_side_bus_active,
  ic_side_evaluation_active,
  ic_side_end_active,
  ic_side_pay_active,
];

export default function Academy() {
  const [isShowNav, setIsShowNav] = useState(true);

  return (
    <div id="academy" className="container">
      <AcademyNav isShowNav={isShowNav} setIsShowNav={setIsShowNav} />
      <div
        style={{ width: "100vw" }}
        onTouchStart={() => {
          setIsShowNav(false);
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}

function AcademyNav({ isShowNav, setIsShowNav }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentMenu = pathname.split("/")[2];

  const sideMenuList = [
    ["학원", "info"],
    ["선생님", "teachers"],
    ["강의실", "classroom"],
    ["시간표", "schedule"],
    ["셔틀버스", "shuttle"],
    ["평가관리", "evaluation"],
  ];

  const sideMenuEtcList = [
    ["퇴직자", "retiree"],
    ["결제", "payment"],
  ];

  const handlePage = (link) => {
    setIsShowNav(false);
    if (link === "info") return navigate("/academy/" + link);
    else return navigate("/academy/" + link + "/1");
  };

  return (
    <>
      <nav className={"academy_nav " + (!isShowNav && "hide")}>
        <button
          className="btn_close_nav"
          onClick={() => {
            setIsShowNav(!isShowNav);
          }}
        >
          <img src={ic_side_arrow} />
        </button>
        <ul>
          {sideMenuList.map((el, idx) => {
            if (el[0] === "평가관리" && !permissionCheck(1)) return false;
            return (
              <li
                key={idx}
                className={currentMenu === el[1] && "active"}
                onClick={() => {
                  handlePage(el[1]);
                }}
              >
                <div className="icon_wrap">
                  <img
                    src={currentMenu === el[1] ? iconsActive[idx] : icons[idx]}
                    alt=""
                  />
                </div>
                <span>{el[0]}</span>
              </li>
            );
          })}
        </ul>
        <ul className="etc_list">
          {permissionCheck(1) &&
            sideMenuEtcList.map((el, idx) => {
              return (
                <li
                  key={idx}
                  className={currentMenu === el[1] && "active"}
                  onClick={() => {
                    handlePage(el[1]);
                  }}
                >
                  <div className="icon_wrap">
                    <img
                      src={
                        currentMenu === el[1]
                          ? iconsActive[idx + 6]
                          : icons[idx + 6]
                      }
                      alt=""
                    />
                  </div>
                  <span>{el[0]}</span>
                </li>
              );
            })}
        </ul>
      </nav>
    </>
  );
}
