import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";
import PieGraph from "components/PieGraph";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import formatPhoneNumber from "utils/formatPhoneNumber";
import formatText from "utils/formatText";

export default function StudentDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI(
      "post",
      "student/detail",
      {
        student_id: id,
      },
      false
    );
    if (!res) {
      return setMessage({
        text: alertMsg.bad_req,
        confirm: () => navigate(-1),
      });
    }
    setResData(res.data.data);
  };

  const submitData = async (e) => {
    const res = await requestAPI("post", "student/payment/notify", {
      student_id: id,
      payment_history_id: e.target.dataset.id,
    });
    if (!res) return;
    setMessage(alertMsg.payment_request);
  };

  const submitInviteData = async () => {
    const res = await requestAPI("post", "milivily/member/send-sms", {
      phone_number: resData.student.parent_phone_number,
      student_name: resData.student.student_name,
      student_birth: formatDate(resData.student.student_birth),
    });
    if (!res) return;
    setMessage(alertMsg.invite);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="student" className="container background_white">
      <div className="inner">
        <Loading />
        <section className="page_content_wrap ">
          <header>
            <h2>학생정보</h2>
            <div className="btn_group">
              <ReturnToList />
            </div>
          </header>

          <table className="detail align_l desktop mt_44">
            <colgroup>
              <col width={"15%"} />
              <col width={"35%"} />
              <col width={"15%"} />
              <col width={"auto"} />
            </colgroup>

            <tr>
              <th>이름</th>
              <td>
                {resData?.student.student_name} (
                {resData?.student.student_gender})
              </td>
              <th>휴대폰번호</th>
              <td>
                {formatPhoneNumber(resData?.student.student_phone_number) ||
                  formatPhoneNumber(resData?.student.parent_phone_number) +
                    "(보호자)"}
              </td>
            </tr>

            <tr>
              <th>상태</th>
              <td>{resData?.student.student_status}</td>
              <th>생년월일</th>
              <td>{formatDate(resData?.student.student_birth, ".")}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colSpan={3}>
                {resData
                  ? `${resData.student.postal_code} ${resData.student.address} ${resData.student.detail_address}`
                  : ""}
              </td>
            </tr>

            <tr>
              <th>등원버스</th>
              <td colSpan={3} className="pd_none">
                <table className="inner_table mt_0">
                  <tr>
                    <th>유형</th>
                    <th>탑승요일/시간</th>
                    <th>탑승위치</th>
                  </tr>
                  {resData?.shuttle_stop_list.map((el) => {
                    return (
                      <tr className="class_info">
                        <td>{el.direction}</td>
                        <td>
                          {el.week}/{el.boarding_time?.slice(0, -3)}
                        </td>
                        <td>{el.location}</td>
                      </tr>
                    );
                  })}
                </table>
              </td>
            </tr>

            <tr>
              <th>현재수업</th>
              <td colSpan={3} className="pd_none">
                <table className="inner_table mt_0">
                  <tr>
                    <th>수업명</th>
                    <th>수납일</th>
                    <th>수납</th>
                  </tr>
                  {resData?.class_list.map((el) => {
                    return (
                      <tr className="class_info">
                        <td>{el.class_name}</td>
                        <td>{el.payment_date}일</td>
                        <td>
                          {el.is_paid
                            ? formatDate(el.is_paid_date, ".")
                            : "미납"}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </td>
            </tr>

            <tr>
              <th>과거수업</th>
              <td colSpan={3}>
                {resData?.past_class_list.map((el) => el.class_name).join(",")}
              </td>
            </tr>

            <tr>
              <th>가족</th>
              <td colSpan={5}>
                {resData?.family ? (
                  JSON.parse(resData.family.family.info).map((el) => {
                    return (
                      <>
                        {el.name} ({el.realation}){" "}
                        {formatPhoneNumber(el.phone_number)}
                        <br />
                      </>
                    );
                  })
                ) : (
                  <div className="btn_group">
                    <button
                      className="btn_default small"
                      onClick={submitInviteData}
                      disabled={resData?.is_invited}
                    >
                      {resData?.is_invited ? "초대완료" : "초대하기"}
                    </button>
                  </div>
                )}
              </td>
            </tr>

            <tr>
              <th>메모</th>
              <td colSpan={3}>{formatText(resData?.student.memo)}</td>
            </tr>
            <tr>
              <th>등록자</th>
              <td>{resData?.student.created_by}</td>
              <th>등록일시</th>
              <td>{formatDate(resData?.student.created_at, ".")}</td>
            </tr>
          </table>

          <table className="detail align_l mobile mt_24">
            <colgroup>
              <col width={"20%"} />
            </colgroup>

            <tr>
              <th>이름</th>
              <td>
                {resData?.student.student_name} (
                {resData?.student.student_gender})
              </td>
            </tr>

            <tr>
              <th>휴대폰번호</th>
              <td>
                {formatPhoneNumber(resData?.student.student_phone_number) ||
                  formatPhoneNumber(resData?.student.parent_phone_number) +
                    "(보호자)"}
              </td>
            </tr>

            <tr>
              <th>상태</th>
              <td>{resData?.student.student_status}</td>
            </tr>

            <tr>
              <th>생년월일</th>
              <td>{formatDate(resData?.student.student_birth, ".")}</td>
            </tr>

            <tr>
              <th>주소</th>
              <td>
                {resData
                  ? `${resData.student.postal_code} ${resData.student.address} ${resData.student.detail_address}`
                  : ""}
              </td>
            </tr>

            <tr>
              <th colSpan={2}>등원버스</th>
            </tr>

            <tr>
              <td className="pd_none" colSpan={2}>
                <table className="inner_table border_none mt_0">
                  <tr>
                    <th>유형</th>
                    <th>탑승요일/시간</th>
                    <th>탑승위치</th>
                  </tr>
                  {resData?.shuttle_stop_list.map((el) => {
                    return (
                      <tr className="class_info">
                        <td>{el.direction}</td>
                        <td>
                          {el.week}/{el.boarding_time?.slice(0, -3)}
                        </td>
                        <td>{el.location}</td>
                      </tr>
                    );
                  })}
                </table>
              </td>
            </tr>

            <tr>
              <th colSpan={2}>현재수업</th>
            </tr>

            <tr>
              <td className="pd_none" colSpan={2}>
                <table className="inner_table mt_0">
                  <tr>
                    <th>수업명</th>
                    <th>수납일</th>
                    <th>수납</th>
                  </tr>
                  {resData?.class_list.map((el) => {
                    return (
                      <tr className="class_info">
                        <td>{el.class_name}</td>
                        <td>{el.payment_date}일</td>
                        <td>
                          {el.is_paid
                            ? formatDate(el.is_paid_date, ".")
                            : "미납"}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </td>
            </tr>

            <tr>
              <th>과거수업</th>
              <td>
                {resData?.past_class_list.map((el) => el.class_name).join(",")}
              </td>
            </tr>

            <tr>
              <th>가족</th>
              <td>
                {resData?.family ? (
                  JSON.parse(resData.family.family.info).map((el) => {
                    return (
                      <>
                        {el.name} ({el.realation}){" "}
                        {formatPhoneNumber(el.phone_number)}
                        <br />
                      </>
                    );
                  })
                ) : (
                  <div className="btn_group">
                    <button
                      className="btn_default small slim"
                      onClick={submitInviteData}
                      disabled={resData?.is_invited}
                    >
                      {resData?.is_invited ? "초대완료" : "초대하기"}
                    </button>
                  </div>
                )}
              </td>
            </tr>

            <tr>
              <th>메모</th>
              <td>{formatText(resData?.student.memo)}</td>
            </tr>
            <tr>
              <th>등록자</th>
              <td>{resData?.student.created_by}</td>
            </tr>

            <tr>
              <th>등록일시</th>
              <td>{formatDate(resData?.student.created_at, ".")}</td>
            </tr>
          </table>
        </section>

        <section className="page_content_wrap payment_history mt_56">
          <header>
            <h2>수납기록</h2>
          </header>
          {resData && !resData.payment_list[0] && (
            <p className="empty_data">표시할 수납기록이 없습니다.</p>
          )}

          {/* <div className="attendance"> */}

          {resData && resData.payment_list[0] && (
            <section className="table_wrap">
              <table className="detail fixed border_none mb_40">
                <colgroup>
                  <col width={"25%"} />
                  <col width={"20%"} />
                  <col width={"25%"} />
                  <col width={"30%"} />
                </colgroup>
                <thead>
                  <tr>
                    <th>수업명</th>
                    <th>수납여부</th>
                    <th className="desktop">수납월</th>
                    <th>수납일자</th>
                    {/* <th>재결제</th> */}
                    <th>확인</th>
                  </tr>
                </thead>
                <tbody>
                  {resData?.payment_list.map((el, idx) => {
                    if (el.status)
                      return (
                        <tr key={idx}>
                          <td
                            className="hide_text pointer"
                            title={el.class_name}
                          >
                            {el.class_name}
                          </td>
                          <td className={el.status === "미납" && "error"}>
                            {el.status}
                          </td>
                          <td className="desktop">
                            {el.payment_month.slice(-2)}월
                          </td>
                          <td>
                            {el.status === "완료" ? (
                              <>
                                <span className="desktop">
                                  {formatDate(el.pay_end_date, ".")}
                                </span>
                                <span className="mobile">
                                  {formatDate(el.pay_end_date, ".").slice(2)}
                                </span>
                              </>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td>
                            {el.status === "미납" ? (
                              <div className="btn_group short">
                                <button
                                  className="btn_default slim green3 pr_12 pl_12"
                                  data-id={el.id}
                                  onClick={submitData}
                                >
                                  알림발송
                                </button>
                              </div>
                            ) : (
                              <>
                                <span className="desktop">
                                  {el.payment_date || "-"}
                                </span>
                                <span className="mobile">
                                  {el.payment_date?.slice(2) || "-"}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </section>
          )}
        </section>

        <AttendanceStatus student_id={id} />
        <HomeworkStatus student_id={id} />

        <div className="btn_group desktop">
          <button></button>
          <button
            className="btn_default half"
            onClick={() => {
              navigate("/student/edit/" + id, {
                state: {
                  data: {
                    ...resData.student,
                    class_list: resData?.class_list,
                  },
                },
              });
            }}
          >
            수정
          </button>
        </div>
        <div className="btn_group mobile">
          <button
            className="btn_default "
            onClick={() => {
              navigate("/student/edit/" + id, {
                state: {
                  data: {
                    ...resData.student,
                    class_list: resData?.class_list,
                  },
                },
              });
            }}
          >
            수정
          </button>
        </div>
      </div>
    </div>
  );
}

function AttendanceStatus({ student_id, mna_manager_id }) {
  const { requestAPI } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currPage, setCurrPage] = useState(0);
  const [countData, setCountData] = useState({
    등원: 0,
    지각: 0,
    결석: 0,
  });

  const getData = async () => {
    const res = await requestAPI("post", "student/detail/attendance", {
      student_id,
      // mna_manager_id,
      page: currPage,
      size: 7,
    });
    if (!res) return;
    const countObj = {};
    res.data.data.attendance_history_count_list.forEach((el) => {
      countObj[el.status] = el.cnt;
    });
    setCountData(countObj);
    setResData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, [currPage]);

  return (
    <section className="page_content_wrap mt_56">
      <header className="mb_44">
        <h2>출결현황</h2>
      </header>

      {resData && !resData.attendance_list[0] ? (
        <p className="empty_data">표시할 출결 현황이 없습니다.</p>
      ) : (
        <div className="attendance">
          <section className="graph_wrap">
            <div className="graph">
              <PieGraph
                data={[countData["등원"], countData["지각"], countData["결석"]]}
              />
            </div>
            <ul>
              <li>
                <span></span>정상등원
              </li>
              <li>
                <span></span>지각
              </li>
              <li>
                <span></span>결석
              </li>
            </ul>
          </section>
          <section className="table_wrap">
            <table className="detail border_none mb_40">
              <thead>
                <tr>
                  <th>수업명</th>
                  <th>수업일시</th>
                  <th>상태</th>
                  <th>리워드</th>
                  <th>담당 선생님</th>
                </tr>
              </thead>
              <tbody>
                {resData?.attendance_list.map((el) => {
                  return (
                    <tr>
                      <td className="hide_text pointer" title={el.class_name}>
                        {el.class_name}
                      </td>
                      <td>{el.class_date}</td>
                      <td>{el.status}</td>
                      <td>{el.reward}P</td>
                      <td>{el.teacher_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              currPage={currPage}
              setCurrPage={setCurrPage}
              lastPage={resData?.totalPages || 0}
              changeURL={false}
              size={7}
            />
          </section>
        </div>
      )}
    </section>
  );
}

function HomeworkStatus({ student_id }) {
  const { requestAPI } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currPage, setCurrPage] = useState(0);
  const [countData, setCountData] = useState({
    // 수행: 0,
    // 미수행: 0,
  });

  const getData = async () => {
    const res = await requestAPI("post", "student/detail/homework", {
      student_id,
      // mna_manager_id,
      page: currPage,
      size: 7,
    });
    if (!res) return;
    const countObj = {};
    res.data.data.homework_history_count_list.forEach((el) => {
      countObj[el.status] = el.cnt;
    });
    setCountData(countObj);
    setResData(res.data.data);
  };

  const getDaysDiff = (due, fin) => {
    const timeDiff = new Date(due) - new Date(fin);
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 0 ? "+" + daysDiff * -1 : daysDiff;
  };

  useEffect(() => {
    getData();
  }, [currPage]);

  return (
    <section className="page_content_wrap mt_56">
      <header className="mb_44">
        <h2>숙제현황</h2>
      </header>
      {resData && !resData.homework_list.content[0] ? (
        <p className="empty_data">표시할 숙제 현황이 없습니다.</p>
      ) : (
        <div className="homework">
          <section className="graph_wrap">
            <div className="graph">
              <PieGraph data={[countData["완료"], countData["미수행"]]} />
            </div>
            <ul>
              <li>
                <span></span>수행
              </li>
              <li>
                <span></span>미수행
              </li>
            </ul>
          </section>
          <section className="table_wrap">
            <table className="detail fixed border_none mb_40">
              <thead>
                <tr>
                  <th>수업명</th>
                  <th>숙제내용</th>
                  <th>완료여부</th>
                  <th>리워드</th>
                  <th>담당 선생님</th>
                </tr>
              </thead>
              <tbody>
                {resData?.homework_list.content.map((el) => {
                  return (
                    <tr>
                      <td className="hide_text pointer" title={el.class_name}>
                        {el.class_name}
                      </td>
                      <td className="hide_text pointer" title={el.homework}>
                        {el.homework}
                      </td>
                      <td>
                        {el.is_done === "완료"
                          ? new Date(el.due_date) - new Date(el.fin_date) < 0
                            ? " 초과 완료"
                            : "완료"
                          : "미수행"}
                        <br />
                        {el.is_done === "완료" &&
                          el.due_date !== el.fin_date &&
                          "(D" + getDaysDiff(el.due_date, el.fin_date) + ")"}
                      </td>
                      <td>{el.reward}P</td>
                      <td>{el.teacher_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              currPage={currPage}
              setCurrPage={setCurrPage}
              lastPage={resData?.homework_list.totalPages || 0}
              changeURL={false}
              size={7}
            />
          </section>
        </div>
      )}
    </section>
  );
}
