import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import landing from "assets/img/Img_landing.png";
import intro_tab_list_1 from "assets/img/intro_tab_list_1.png";
import intro_tab_list_2 from "assets/img/intro_tab_list_2.png";
import intro_tab_list_3 from "assets/img/intro_tab_list_3.png";
import intro_tab_list_4 from "assets/img/intro_tab_list_4.png";
import intro_tab_list_5 from "assets/img/intro_tab_list_5.png";
import intro_tab_list_6 from "assets/img/intro_tab_list_6.png";
import intro_tab_list_7 from "assets/img/intro_tab_list_7.png";
import intro_ic_owner from "assets/img/intro_ic_owner.png";
import intro_ic_teacher from "assets/img/intro_ic_teacher.png";
import intro_ic_check from "assets/img/intro_ic_check.png";
import intro_tab_con1_1 from "assets/img/intro_tab_con1_1.png";
import intro_tab_con1_2 from "assets/img/intro_tab_con1_2.png";
import intro_tab_con2_1 from "assets/img/intro_tab_con2_1.png";
import intro_tab_con2_2 from "assets/img/intro_tab_con2_2.png";
import intro_tab_con3_1 from "assets/img/intro_tab_con3_1.png";
import intro_tab_con3_2 from "assets/img/intro_tab_con3_2.png";
import intro_tab_con4_1 from "assets/img/intro_tab_con4_1.png";
import intro_tab_con5_1 from "assets/img/intro_tab_con5_1.png";
import intro_tab_con5_2 from "assets/img/intro_tab_con5_2.png";
import intro_tab_con6_1 from "assets/img/intro_tab_con6_1.png";
import intro_tab_con6_2 from "assets/img/intro_tab_con6_2.png";
import intro_tab_con7_1 from "assets/img/intro_tab_con7_1.png";
import intro_tab_con7_2 from "assets/img/intro_tab_con7_2.png";
import intro_con1_ev from "assets/img/intro_con1_ev.png";
import intro_con1_mv from "assets/img/intro_con1_mv.png";
import ic_blog from "assets/img/icon/ic_blog.png";
import ic_blog_hover from "assets/img/icon/ic_blog_hover.png";
import ic_insta from "assets/img/icon/ic_insta.png";
import ic_insta_hover from "assets/img/icon/ic_insta_hover.png";
import ic_kakao from "assets/img/icon/ic_kakao.png";
import ic_kakao_hover from "assets/img/icon/ic_kakao_hover.png";
import useModalHandler from "hooks/useModalHandler";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isLoginAtom, messageAtom } from "atoms/atoms";
import { url } from "variable/url";
import loginCheck from "utils/loginCheck";

export default function IntroDesktop() {
  const navigate = useNavigate();
  const [, setIsLogin] = useRecoilState(isLoginAtom);
  const setMessage = useSetRecoilState(messageAtom);
  const { ModalComponent, openModal } = useModalHandler();
  const [tab, setTab] = useState("결제수납");
  const [isFloating, setIsFloating] = useState(false);
  const tabs = [
    "결제수납",
    "소속 직원 관리",
    "수업만족도평가",
    "다가오는 수업관리",
    "알람공지",
    "출결 및 학습현황",
    "셔틀버스 현황",
  ];
  const tabImages = [
    intro_tab_list_1,
    intro_tab_list_2,
    intro_tab_list_3,
    intro_tab_list_4,
    intro_tab_list_5,
    intro_tab_list_6,
    intro_tab_list_7,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const tabCons = document.querySelectorAll(".intro_tab_con.desktop");

    const scrollEvent = () => {
      const intrtoMain = document.querySelector(".intro_main").clientHeight;
      if (window.scrollY >= intrtoMain + 146) setIsFloating(true);
      else setIsFloating(false);

      let targetTab = "";
      tabCons.forEach((el, idx) => {
        if (el.getBoundingClientRect().top - 320 <= 0) {
          targetTab = tabs[idx];
        }
        if (tabCons[0].getBoundingClientRect().top > 0) {
          targetTab = "결제수납";
        }
        if (
          document.querySelector(".intro_con.con1").getBoundingClientRect()
            .top -
            320 <
          0
        ) {
          targetTab = "";
        }
      });
      setTab(targetTab);
    };

    window.addEventListener("scroll", scrollEvent);

    loginCheck(async () => {
      await axios.put(url.server + "teacher/logout");
      localStorage.clear();
      setIsLogin(false);
      setMessage("로그아웃 되었습니다.");
    });

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <main id="intro" className="container desktop">
      <ModalComponent />
      <section className="intro_wrap">
        <section className="intro_main">
          <section className="intro_tit">
            <h1 className="title">
              성공으로 이끄는
              <br />
              <span className="tit_point">학원운영</span> 에듀빌리
            </h1>
            <h2 className="top_title">
              하나부터 열까지 에듀빌리
              <span className="strong"> 하나로</span> 해결하세요.
            </h2>
            <h2 className="sub_title">
              #원생관리 #일정알림 #스마트한 학원 관리
            </h2>

            <div className="btn_group">
              <button className="login_btn" onClick={() => navigate("/login")}>
                로그인
              </button>
              <button
                className="sign_btn"
                onClick={() => navigate("/auth/join/step1")}
              >
                무료로 시작하기
              </button>
            </div>
          </section>
          <section className="intro_func">
            <img src={landing} alt="" />
          </section>
        </section>
        <section className="intro_sub">
          <section className={"intro_tab_list " + (isFloating && "floating")}>
            <p className="intro_con_desc">
              학원 운영에만 집중할 수 있도록
              <br />
              <strong>꼭 필요한 기능만 담았습니다.</strong>
            </p>
            <ul>
              {tabs.map((el, idx) => {
                return (
                  <li
                    className={tab === el && "active"}
                    onClick={() => {
                      document
                        .querySelector(".scroll_target_con" + (idx + 1))
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <div className="img_wrap">
                      <img src={tabImages[idx]} alt="" />
                    </div>
                    <p>{el}</p>
                  </li>
                );
              })}
            </ul>
            {tab && tabs.indexOf(tab) < 3 && (
              <div className="intro_con_desc_sub">
                <img src={intro_ic_owner} alt="" />
                <span className="bubble">
                  원장님에게 필요한 기능만 모아져있어요 !
                </span>
              </div>
            )}
            {tab && tabs.indexOf(tab) >= 3 && (
              <div className="intro_con_desc_sub">
                <img src={intro_ic_teacher} alt="" />
                <span className="bubble">
                  선생님에게 필요한 기능만 모아져있어요 !
                </span>
              </div>
            )}
          </section>

          {isFloating && <div style={{ height: 466 + "px" }}></div>}

          <section className="intro_tab_con desktop con1">
            <hr className="scroll_target_con1" />
            <header>
              <h3>
                손쉽고 유연한
                <strong>결제수납</strong>
              </h3>
              <p>
                간단한 결제수납방법으로
                <br />
                유연하고 빠르게 결제와 수납이 가능해요.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  유연한 결제와 수납관리
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  클릭 한번만으로 결제 완료 !
                </li>
              </ul>
            </header>
            <img src={intro_tab_con1_1} alt="" />
            <img src={intro_tab_con1_2} alt="" className="right" />
          </section>

          <section className="intro_tab_con desktop con2">
            <hr className="scroll_target_con2" />
            <header>
              <h3>
                강사 능력 및 개발 평가
                <strong>소속 직원 관리</strong>
              </h3>
              <p>
                재직자부터 퇴직자 현황까지 한눈에 확인하고
                <br />
                직원 선생님 급여 관리까지 헷갈릴 필요 없어요.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  소속 선생님의 간편한 급여 관리
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  재직자 및 퇴직 현황
                </li>
              </ul>
            </header>
            <img src={intro_tab_con2_1} alt="" className="right" />
            <img src={intro_tab_con2_2} alt="" />
          </section>

          <section className="intro_tab_con desktop con3">
            <hr className="scroll_target_con3" />
            <header>
              <h3>
                수업의 품질 개선
                <strong>수업 만족도 평가</strong>
              </h3>
              <p>
                객관적인 수업 만족도 평가를 통하여
                <br />
                우리 학원의 지속적인 성장을 책임집니다.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  선생님의 수업 만족도와 퀼리티 향상
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  객관적인 수업평가로 신뢰도 상승
                </li>
              </ul>
            </header>
            <img src={intro_tab_con3_1} alt="" />
            <img src={intro_tab_con3_2} alt="" className="right" />
          </section>

          <section className="intro_tab_con desktop con4">
            <hr className="scroll_target_con4" />
            <header>
              <h3>
                한눈에 확인하는
                <strong>다가오는 수업관리</strong>
              </h3>
              <p>
                선생님의 다가오는 스케줄과 일정을 홈화면에서
                <br /> 한눈에 파악할 수 있어 원활한 수업 운영이 가능합니다.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  3분만에 끝나는 수업준비
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  학원의 모든 수업 데이터를 실시간 확인 가능
                </li>
              </ul>
            </header>
            <img src={intro_tab_con4_1} alt="" />
          </section>

          <section className="intro_tab_con desktop con5">
            <hr className="scroll_target_con5" />
            <header>
              <h3>
                학생 개별 공지 발송
                <strong>공지사항 알림</strong>
              </h3>
              <p>
                학생 개별에게 숙제, 알림, 준비물 등<br />
                공지사항을 클릭 한번으로 전송이 가능해요.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  에듀빌리 앱 자체 공지로 상담시간 단축
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  클릭 한번에 전원 공지 발송 !
                </li>
              </ul>
            </header>
            <img src={intro_tab_con5_1} alt="" />
            <img src={intro_tab_con5_2} alt="" className="right" />
          </section>

          <section className="intro_tab_con desktop con6">
            <hr className="scroll_target_con6" />
            <header>
              <h3>
                학생관리 자동화
                <strong>출결 및 학습현황</strong>
              </h3>
              <p>
                학생들의 출결 및 학습현황을 그래프와 표로
                <br />
                나타내어 효율적인 학생관리가 가능합니다.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  한번에 끝내는 출결, 숙제관리
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  많은 학생들도 한번에 관리 가능해요
                </li>
              </ul>
            </header>
            <img src={intro_tab_con6_1} alt="" className="right" />
            <img src={intro_tab_con6_2} alt="" />
          </section>

          <section className="intro_tab_con desktop con7">
            <hr className="scroll_target_con7" />
            <header>
              <h3>
                안전한 등하원
                <strong>셔틀버스 현황</strong>
              </h3>
              <p>
                셔틀버스의 위치를 실시간으로 확인할 수 있고
                <br /> 아이들의 등하원 여부도 파악이 가능해요.
              </p>
              <ul>
                <li>
                  <img src={intro_ic_check} alt="" />
                  한눈에 확인 가능한 등하원 현황
                </li>
                <li>
                  <img src={intro_ic_check} alt="" />
                  셔틀버스의 실시간 위치 확인 가능
                </li>
              </ul>
            </header>
            <img src={intro_tab_con7_1} alt="" className="right" />
            <img src={intro_tab_con7_2} alt="" />
          </section>

          <section className="intro_con con1">
            <h1>
              우리동네 1등 학원 비결은?
              <strong>에듀빌리를 선택해야하는 이유</strong>
            </h1>
            <section className="wrap">
              <div className="bg">
                <section className="item">
                  <div className="img_wrap">
                    <img src={intro_con1_ev} alt="" />
                  </div>
                  <div className="text_wrap">
                    <p>
                      <br /> 모든 기능을 무료로 사용할 수 있어요.
                      <br /> 번거로운 견적 요청이 없어요.
                      <br /> 부담스러운 약정이 없어요.
                    </p>
                  </div>
                </section>
              </div>
              <span className="plus">+</span>
              <div className="bg">
                <section className="item">
                  <div className="img_wrap">
                    <img src={intro_con1_mv} alt="" />
                  </div>
                  <div className="text_wrap">
                    <p>
                      학부모, 원생과{" "}
                      <strong className="mv">원활한 커뮤니케이션 창구</strong>
                      <br />
                      선생님, 학원 홍보 효과까지
                    </p>
                  </div>
                </section>
              </div>
            </section>
          </section>

          <section className="intro_con con2">
            <p>
              <strong>에듀빌리를 무료로 사용해 보세요</strong>
            </p>
            <button
              onClick={() => {
                openModal("contactus");
              }}
            >
              문의하기 →
            </button>
          </section>
        </section>
        <footer>
          <section className="company_info_wrap">
            <h1>버터플라잉 주식회사</h1>
            <p>
              사업자등록번호: 180-86-02730
              <br /> 대표이사: 신형
              <br />
              통신판매업신고번호: 제 2023-대전유성-1773 호<br />
              본사: 34138 대전광역시 유성구 대학로 179, 디이동
              <br />
              연구소: 05855 서울 송파구 법원로8길 8, 문정역2차SKV1 305호
              <br />
              대표전화: <span>1551-8754</span> <br />
              문의: <span>support@butterflying.kr</span>
            </p>
            <ul>
              <li>
                <a
                  target="blank"
                  href="https://www.butterflying.kr/term.html#terms"
                >
                  서비스이용약관
                </a>
              </li>
              <li>
                <a
                  target="blank"
                  href="https://www.butterflying.kr/term.html#locationInfo"
                >
                  위치정보이용약관
                </a>
              </li>
              <li>
                <a
                  target="blank"
                  href="https://www.butterflying.kr/term.html#privacy"
                >
                  개인정보처리약관
                </a>
              </li>
            </ul>
          </section>
          <section className="sns_wrap">
            <ul>
              <li>
                <a
                  href="https://blog.naver.com/butterflying_family"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ic_blog} alt="" />
                  <img src={ic_blog_hover} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/mily_vily/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ic_insta} alt="" />
                  <img src={ic_insta_hover} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://pf.kakao.com/_qBdxoxj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ic_kakao} alt="" />
                  <img src={ic_kakao_hover} alt="" />
                </a>
              </li>
            </ul>
          </section>
        </footer>
      </section>
    </main>
  );
}
