import { useNavigate } from "react-router-dom";
import useValidation from "hooks/useValidation";
import useCategoryList from "hooks/useCategoryList";
import useFindPostAddr from "hooks/useFindPostAddr";
import useFileHandler from "hooks/useFileHandler";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import useDatePicker from "hooks/useDatePicker";
import formatDate from "utils/formatDate";
import useModalHandler from "hooks/useModalHandler";
import { useEffect, useState } from "react";
import AddReferrer from "components/modal/AddReferrer";

export default function JoinCreate() {
  const navigate = useNavigate();
  const { form, errorCheck, validAttributes } = useValidation();
  const [dataForm, , attributes] = useInputHandler({
    academy_name: "",
  });
  const { DaumPost, userAddr, detailAddr, openDaumPost } = useFindPostAddr();
  const [CategoryList, selected] = useCategoryList("field");
  const { fileData, fileInputRef, uploadFile, uploadS3 } = useFileHandler(
    ["jpg", "jpeg", "png", "pdf"],
    10
  );
  const { DatePickerModule, specificDate } = useDatePicker();
  const { ModalComponent, openModal, sendModalData } = useModalHandler();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [paymentData, setPaymentData] = useState({});
  const [businessType, setBusinessType] = useState("개인사업자");

  const [openAddReferrer, setOpenAddReferrer] = useState(false);
  const [resBody, setResBody] = useState({});

  const submitData = async () => {
    if (!fileData[0]) return setMessage("사업자 등록증을 첨부해주세요.");
    const uploader = localStorage.getItem("acadmey_id") ? "manager" : "teacher";
    const licenseData = await uploadS3(uploader);

    if (licenseData == false) return;

    const body = {
      ...dataForm,
      // ...paymentData,
      ceo_name: form.mb_name.val,
      postal_code: userAddr.zonecode,
      address: userAddr.addr,
      address_detail: userAddr.detailAddr,
      license_no: form.license_no.val,
      tel: form.company_ph.val,
      code: form.company_ph.val.slice(-4),
      fields: selected,
      license_path: licenseData,
      opening_date: formatDate(specificDate),
      type: businessType,
    };

    const res = await requestAPI("post", "academy/sign-up", body);
    if (!res) return;
    if (res.data.data.status) {
      setMessage({
        text: alertMsg.create,
        confirm: () =>
          // navigate("/auth/join/complete", { state: { data: { ...body } } }),
          {
            setOpenAddReferrer(true);
            setResBody(body);
          },
      });
    } else {
      navigate("/auth/join/hold", {
        state: { ocr_academy_id: res.data.data.ocr_academy_id },
      });
    }
  };

  const checkCreateData = () => {
    return (
      Object.values(dataForm).every((el) => el) &&
      userAddr.addr &&
      form.company_ph.isValid &&
      form.mb_name.isValid &&
      selected[0]
    );
  };

  const selectBusinessType = (e) => {
    setBusinessType(e.target.innerText);
  };

  useEffect(() => {
    setPaymentData(sendModalData);
  }, [sendModalData]);

  const closeAddReferrer = () => {
    setOpenAddReferrer(false);
    navigate("/auth/join/complete", { state: { data: { ...resBody } } });
  };

  return (
    <main id="joinCreate" className="container align_center">
      <Loading />
      <div className="inner">
        <section className="input_group">
          <div className="input_item_group">
            <dl>
              <dt>사업자 구분</dt>
              <dd>
                <div className="btn_group">
                  {["개인사업자", "법인사업자"].map((el) => {
                    return (
                      <button
                        className={
                          "btn_default " + (businessType !== el && "border")
                        }
                        onClick={selectBusinessType}
                      >
                        {el}
                      </button>
                    );
                  })}
                </div>
              </dd>
            </dl>
          </div>
          <div className="input_item_group">
            <dl>
              <dt>사업자 등록 번호</dt>
              <dd>
                <input
                  type="text"
                  className={"input " + errorCheck("license_no")?.state}
                  {...validAttributes("license_no")}
                  data-format="number"
                />
              </dd>
            </dl>
          </div>
          <div className="input_item_group type_2">
            <dl>
              <dt>사업자 등록증</dt>
              <dd>
                <div className="btn_group short">
                  <input
                    ref={fileInputRef}
                    id="file"
                    type="file"
                    onChange={uploadFile}
                    data-format="number"
                  />
                  <button className="btn_default half">
                    <label htmlFor="file">파일첨부</label>
                  </button>
                </div>
                <span className="hide_text">
                  {fileData[0]?.file.name || "등록된 파일 없음"}
                </span>
              </dd>
            </dl>
          </div>
          <div className="input_item_group">
            <dl>
              <dt>상호명</dt>
              <dd>
                <input
                  type="text"
                  className="input"
                  {...attributes("academy_name")}
                />
              </dd>
            </dl>
          </div>
          <div className="input_item_group type_2">
            <dl>
              <dt>대표자명</dt>
              <dd>
                <input
                  type="text"
                  className={"input half " + errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </dd>
            </dl>
            <dl className="">
              <dt>개업일자</dt>
              <dd>
                <DatePickerModule
                  type="specific"
                  className="half"
                  minDate={"off"}
                  maxDate={"on"}
                />
              </dd>
            </dl>
          </div>
          <div className="input_item_group ">
            <dl>
              <dt>사업장 주소</dt>
              <dd>
                <input
                  type="text"
                  className="input half"
                  value={userAddr.zonecode}
                  readOnly
                />
                <div className="btn_group short">
                  <button className="btn_default half" onClick={openDaumPost}>
                    주소찾기
                  </button>
                </div>
              </dd>
            </dl>
            <dl>
              <dd>
                <input
                  type="text"
                  className="input"
                  value={userAddr.addr}
                  readOnly
                />
              </dd>
            </dl>
            <dl>
              <dd>
                <input
                  type="text"
                  className="input"
                  value={userAddr.detailAddr}
                  placeholder="상세주소"
                  onChange={detailAddr}
                />
              </dd>
            </dl>
          </div>
          <div className="input_item_group type_2">
            <dl>
              <dt>
                사업장 전화번호
                <span className="fs_12 ml_8 mobile">
                  * 사업장 식별번호: 뒷자리(4자리) 사용
                </span>
              </dt>
              <dd>
                <input
                  type="text"
                  className={
                    "input desktop half " + errorCheck("company_ph")?.state
                  }
                  {...validAttributes("company_ph")}
                  data-format="number"
                />
                <input
                  type="text"
                  className={"input mobile " + errorCheck("company_ph")?.state}
                  {...validAttributes("company_ph")}
                  data-format="number"
                />
                <span className="desktop">
                  사업장 식별번호: 뒷자리(4자리) 사용
                </span>
              </dd>
            </dl>
          </div>
          <div className="input_item_group">
            <dl>
              <dt>분야</dt>
              <dd>
                <CategoryList />
              </dd>
            </dl>
          </div>
          {/* <div className="input_item_group type_2">
            <dl>
              <dt>결제</dt>
              <dd>
                <div className="btn_group short">
                  <button
                    className="btn_default medium"
                    onClick={() => openModal("addPayment", "add")}
                  >
                    등록
                  </button>
                </div>
                {paymentData?.bank_account && (
                  <p className="">
                    입금 : {paymentData.bank_name} {paymentData.bank_account}
                    <br className="" />
                    CMS :
                  </p>
                )}
              </dd>
            </dl>
          </div> */}
          <div className="btn_group sumbit">
            <button
              className="btn_default"
              onClick={submitData}
              disabled={!checkCreateData()}
            >
              생성
            </button>
          </div>
        </section>
        <DaumPost />
        <ModalComponent />
        {openAddReferrer && (
          <AddReferrer closeModal={closeAddReferrer} currentData={resBody} />
        )}
      </div>
    </main>
  );
}
