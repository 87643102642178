import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useDatePicker from "hooks/useDatePicker";
import Pagination from "components/Pagination";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import formatDate from "utils/formatDate";

export default function HistoryDetail({ closeModal, changeData, currentData }) {
  const content = currentData.data?.content;
  const [dataForm, setDataForm, attributes] = useInputHandler({
    academy_name: content ? content.name : "",
    work_start_date: content ? content.start_date : "",
    work_end_date: content ? content.end_date : "",
    postal_code: content ? content.postal_code : "",
    address: content ? content.address : "",
    detail_address: content ? content.detail_address : "",
    is_working: content ? content.is_working : false,
  });
  const { DatePickerModule, startDate, endDate } = useDatePicker({
    startDate: dataForm.work_start_date && new Date(dataForm.work_start_date),
    endDate: dataForm.work_end_date && new Date(dataForm.work_end_date),
  });
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();
  const [resData, setResData] = useState(false);
  const [position, setPosition] = useState();
  const [keyword, setKeword] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    keyword: "",
  });
  const [selectAcademy, setSelectAcademy] = useState();

  const submitData = async () => {
    if (!dataForm.academy_name) return setMessage("학원을 선택해주세요.");
    const body = {
      ...dataForm,
      work_start_date: formatDate(startDate),
      work_end_date: dataForm.is_working ? null : formatDate(endDate),
    };
    if (currentData.mode !== "add") {
      body.work_history_id = content.working_history_id;
    }
    const apiType = currentData.mode === "add" ? "put" : "patch";
    const apiName = currentData.mode === "add" ? "create" : "update";
    const res = await requestAPI(
      apiType,
      "teacher/work-history/" + apiName,
      body
    );
    if (!res) return;
    setMessage({
      text: currentData.mode === "add" ? alertMsg.add : alertMsg.edit,
      confirm: () => changeData(res),
    });
  };

  const deleteData = () => {
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "teacher/work-history/delete", {
          work_history_id: content.working_history_id,
        });
        if (!res) return;
        setMessage({ text: alertMsg.delete, confirm: () => changeData(res) });
      },
    });
  };

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        ...currSearchData,
        keyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "mongo/academy/search", body);
    if (!res) return;
    setResData(res.data.data.academy_infos);
  };
  // 위치 정보를 가져오는 함수
  function getLocation() {
    // 브라우저가 Geolocation을 지원하는지 확인
    if (navigator.geolocation) {
      // 위치 정보를 가져오는 메소드 호출
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      setMessage("Geolocation이 지원되지 않습니다.");
    }
  }
  // 위치 정보를 성공적으로 가져왔을 때 실행되는 콜백 함수
  function showPosition(position) {
    // 위도와 경도 정보 얻기
    var latitude = position.coords.latitude; // 위도
    var longitude = position.coords.longitude; // 경도
    // 얻은 정보를 사용하거나 출력
    setPosition({ latitude, longitude });
  }
  // 위치 정보를 가져오는 도중 에러가 발생했을 때 실행되는 콜백 함수
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setMessage("위치 공유 권한 허용 후 이용 가능합니다.");
        break;
      case error.POSITION_UNAVAILABLE:
        setMessage("위치 정보를 사용할 수 없습니다.");
        break;
      case error.TIMEOUT:
        setMessage("위치 정보를 가져오는데 시간이 초과되었습니다.");
        break;
      case error.UNKNOWN_ERROR:
        setMessage("알 수 없는 오류가 발생했습니다.");
        break;
      default:
    }
  }

  useEffect(() => {
    if (position) getData();
  }, [currPage, currSearchData]);

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <aside id="addHistory" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>재직이력 {{ add: "추가", edit: "수정" }[currentData.mode]}</h2>
          <div className="btn_group">
            <button className="invitee_code">
              <Link to="/join/invitee">+초대코드로 추가</Link>
            </button>
            <button className="btn_default close" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        <div className="content_scroll_wrap">
          {" "}
          <table className="">
            <thead>
              <tr>
                <th>검색</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <form className="cell_content_wrap">
                    <input
                      type="text"
                      onChange={(e) => {
                        setKeword(e.target.value);
                      }}
                      className="auto"
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default green3 search"
                        onClick={getData}
                        disabled={!position}
                      >
                        검색
                      </button>
                    </div>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="academy_search_list_wrap mt_24">
            <table className="fixed">
              <colgroup>
                <col width={"25%"} />
                <col width={"75%"} />
              </colgroup>
              <thead>
                <tr>
                  <th>학원명</th>
                  <th>학원주소</th>
                </tr>
              </thead>
              <tbody>
                {!resData && (
                  <tr>
                    <td colSpan={2}>검색어를 입력해 주세요.</td>
                  </tr>
                )}
                {resData &&
                  resData.content.map((el, idx) => {
                    return (
                      <tr
                        key={idx}
                        className={
                          selectAcademy?.currPage === currPage &&
                          selectAcademy?.idx === idx &&
                          "select"
                        }
                        onClick={() => {
                          // console.log(el.postal_code);
                          setSelectAcademy({ currPage, idx });
                          setDataForm({
                            ...dataForm,
                            academy_name: el.academy_name,
                            postal_code: el.postal_code || "",
                            address: el.address,
                            detail_address: el.address_dedail || "",
                          });
                        }}
                      >
                        <th>{el.academy_name}</th>
                        <td>{el.address + (el.address_detail || "")}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <Pagination
              currPage={currPage}
              setCurrPage={setCurrPage}
              lastPage={resData?.totalPages || 0}
              changeURL={false}
            />
          </div>
          <table className="mt_24 desktop">
            <thead>
              <tr>
                <th>학원명</th>
                <th colSpan={3}>주소</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className="half"
                      {...attributes("academy_name")}
                      readOnly
                    />
                  </div>
                </td>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className="small"
                      value={dataForm.postal_code}
                      readOnly
                    />
                  </div>
                </td>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className="medium"
                      value={dataForm.address}
                      readOnly
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="desktop">
            <thead>
              <tr>
                <th>재직기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="cell_content_wrap worked_date">
                    <DatePickerModule
                      type="start"
                      minDate={"off"}
                      maxDate={"on"}
                    />
                    -
                    {dataForm.is_working ? (
                      <input type="text" value={"재직중"} readOnly />
                    ) : (
                      <DatePickerModule type="end" maxDate={"on"} />
                    )}
                    <div className="is_working_wrap">
                      <input
                        type="checkbox"
                        className="mr_8"
                        {...attributes("is_working")}
                        checked={dataForm.is_working}
                      />
                      재직 중 입니다.
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="fixed mobile">
            <tbody>
              <tr>
                <th>재직기간</th>
              </tr>
              <tr>
                <td>
                  <div className="cell_content_wrap">
                    <DatePickerModule
                      type="start"
                      minDate={"off"}
                      maxDate={"on"}
                      className={"small"}
                    />
                    ~
                    {dataForm.is_working ? (
                      <input
                        type="text"
                        value={"재직중"}
                        className="half"
                        readOnly
                      />
                    ) : (
                      <DatePickerModule
                        type="end"
                        maxDate={"on"}
                        className={"small"}
                      />
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="cell_content_wrap">
                    <div className="is_working_wrap fs_16">
                      <input
                        id="isWorking"
                        type="checkbox"
                        className="mr_8"
                        {...attributes("is_working")}
                        checked={dataForm.is_working}
                      />
                      <label htmlFor="isWorking">재직 중 입니다.</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {currentData.mode === "edit" && (
          <>
            <div className="btn_group mt_40 desktop">
              <button className="btn_default half del" onClick={deleteData}>
                삭제
              </button>
              <button
                className="btn_default half"
                onClick={() => {
                  submitData("edit");
                }}
              >
                수정
              </button>
            </div>
            <div className="btn_group mt_40 mobile">
              <button className="btn_default  del" onClick={deleteData}>
                삭제
              </button>
              <button
                className="btn_default "
                onClick={() => {
                  submitData("edit");
                }}
              >
                수정
              </button>
            </div>
          </>
        )}
        {currentData.mode === "add" && (
          <>
            <div className="btn_group mt_40 desktop">
              <button></button>
              <button
                className="btn_default half"
                onClick={() => {
                  submitData("add");
                }}
              >
                추가
              </button>
            </div>
            <div className="btn_group mt_40 mobile">
              <button
                className="btn_default "
                onClick={() => {
                  submitData("add");
                }}
              >
                추가
              </button>
            </div>
          </>
        )}
      </div>
    </aside>
  );
}
