import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";
import { UrlConfig } from "../common/config/variable/url.js";

// 나이스 인증 관련 훅
export default function useNiceCert(key = "mb") {
  // nice 인증 데이터 앞에 붙는 이름. 기본값은 mb
  const setMessage = useSetRecoilState(messageAtom);
  const [niceData, setNiceData] = useState();
  let myWindow;

  const eduvilyAppSererUrl = String(UrlConfig.eduvilyAppSererUrl).replace(
    "/api/v1/",
    ""
  );
  const niceUrl = `${eduvilyAppSererUrl}/nice`;

  const openNicePopup = () => {
    // 나이스 인증 페이지를 호출한다.
    myWindow = window.open(
      niceUrl,
      "childForm",
      "width=570, height=350, resizable = no, scrollbars = no"
    );
    window.addEventListener("message", getNiceData); // 인증 절차 완료 시 나이스 인증 페이지에서 보낸 메시지를 받고 getNiceData 함수를 호출한다.
  };

  const getNiceData = async (e) => {
    window.removeEventListener("message", getNiceData); // 위에서 등록한 이벤트 삭제
    if (!e.data.connInfo) return setMessage("다시 시도해주세요."); // 응답데이터가 없으면 에러얼럿 출력
    // 생년월일 포맷팅
    const year = e.data.birthdate && e.data.birthdate.slice(0, 4);
    const month = e.data.birthdate && e.data.birthdate.slice(4, 6);
    const day = e.data.birthdate && e.data.birthdate.slice(6, 8);
    setNiceData({
      [key + "_name"]: e.data.name,
      [key + "_gender"]: e.data.gender === "1" ? "남" : "여",
      [key + "_birth"]: `${year}-${month}-${day}`,
      [key + "_ph"]: e.data.mobile,
      ci: e.data.connInfo,
    });
    myWindow.close();
  };

  return { openNicePopup, niceData };
}
