import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReturnToList from "components/ReturnToList";
import useRequestAPI from "hooks/useRequestAPI";
import useInputHandler from "hooks/useInputHandler";

export default function AcademyEvaluationDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();
  const [dataForm, , attributes] = useInputHandler({ ...state });
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/class/rating/detail", {
      rating_id: id,
    });
    if (!res) return;
    const data = res.data.data.class_rating_detail;
    for (const key in data) {
      try {
        data[key] = JSON.parse(data[key]);
      } catch {
        continue;
      }
    }
    setResData(data);
  };

  const submitData = async () => {
    const res = await requestAPI(
      "patch",
      "academy/class/rating/detail/update",
      {
        rating_id: id,
        owner_rating: dataForm.owner_rating,
      }
    );
    if (!res) return;
    setMessage({
      text: alertMsg.apply,
      confirm: () => navigate("/academy/evaluation/1"),
    });
  };

  const dataFormating = (data, type) => {
    if (typeof data !== "number" && !data) return;
    const text1 = 0 > data?.this_month ? "감소" : "증가";
    const text2 = 0 > data?.one_month_ago ? "감소" : "증가";
    if (typeof data !== "object") {
      return !type ? data || "-" : data ? data + "%" : "-";
    } else {
      switch (type) {
        case "student": {
          return (
            <>
              <strong className={text1 === "감소" ? "donw" : ""}>
                {data.this_month}%
              </strong>
              (전달 대비 학원 전체 {Math.abs(data.one_month_ago)}% {text2})
            </>
          );
        }
        case "homework": {
          return (
            <>
              <strong className={text1 === "감소" ? "donw" : ""}>
                {data.this_month}%
              </strong>
              (전달 대비 {Math.abs(data.one_month_ago)}% {text2})
            </>
          );
        }
        default:
          return `${data.this_month} (전달 대비 ${data.one_month_ago}% ${text2})`;
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="inner evaluation_detail">
      <header>
        <h2>평가하기</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap evaluation_detail">
        <table className="detail fixed desktop">
          <tr>
            <th>수업명</th>
            <td>{resData?.class_name}</td>
            <th>담당선생님</th>
            <td>{resData?.teacher_name}</td>
          </tr>

          <tr>
            <th rowSpan={1}>수업평점</th>
            <td colSpan={3}>{dataFormating(resData?.total_rating)}</td>
          </tr>

          <tr>
            <th rowSpan={5}>항목별 평점</th>
            <td>상담이 친절하고 소통이 원활해요</td>
            <td colSpan={2}>{dataFormating(resData?.rating1)}</td>
          </tr>
          <tr>
            <td>커리큘럼이 우수해요</td>
            <td colSpan={2}>{dataFormating(resData?.rating2)}</td>
          </tr>
          <tr>
            <td>성적이 향상되었어요</td>
            <td colSpan={2}>{dataFormating(resData?.rating3)}</td>
          </tr>
          <tr>
            <td>아이의 만족도가 높아요</td>
            <td colSpan={2}>{dataFormating(resData?.rating4)}</td>
          </tr>
          <tr>
            <td>지인에게 추천하고 싶어요</td>
            <td colSpan={2}>{dataFormating(resData?.rating5)}</td>
          </tr>
          <tr>
            <th>학생 추이</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                {dataFormating(resData?.students_rating, "student")}
              </div>
            </td>
          </tr>
          <tr>
            <th>숙제완료율</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                {dataFormating(resData?.homework_rating, "homework")}
              </div>
            </td>
          </tr>
          <tr>
            <th>총합평점</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                <input
                  type="number"
                  className="half"
                  {...attributes("owner_rating")}
                  step={0.1}
                  min={0}
                />
                점<span className="desc">*지난달 평점 3.7점 입니다.</span>
              </div>
            </td>
          </tr>
        </table>

        <table className="detail fixed mobile">
          <colgroup>{/* <col width={"26%"} /> */}</colgroup>
          <tr>
            <th>수업명</th>
            <td colSpan={3}>{resData?.class_name}</td>
          </tr>
          <tr>
            <th>담당선생님</th>
            <td colSpan={3}> {resData?.teacher_name}</td>
          </tr>
          <tr>
            <th>수업평점</th>
            <td colSpan={3}>{dataFormating(resData?.total_rating)}</td>
          </tr>
          <tr>
            <th colSpan={4}>항목별 평점</th>
          </tr>

          <tr>
            <td colSpan={2}>
              상담이 친절하고
              <br />
              소통이 원활해요
            </td>
            <td colSpan={2}>{dataFormating(resData?.rating1)}</td>
          </tr>
          <tr>
            <td colSpan={2}>커리큘럼이 우수해요</td>
            <td colSpan={2}>{dataFormating(resData?.rating2)}</td>
          </tr>
          <tr>
            <td colSpan={2}>성적이 향상되었어요</td>
            <td colSpan={2}>{dataFormating(resData?.rating3)}</td>
          </tr>
          <tr>
            <td colSpan={2}>아이의 만족도가 높아요</td>
            <td colSpan={2}>{dataFormating(resData?.rating4)}</td>
          </tr>
          <tr>
            <td colSpan={2}>지인에게 추천하고 싶어요</td>
            <td colSpan={2}>{dataFormating(resData?.rating5)}</td>
          </tr>
          <tr>
            <th>학생 추이</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                {dataFormating(resData?.students_rating, "student")}
              </div>
            </td>
          </tr>
          <tr>
            <th>숙제완료율</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                {dataFormating(resData?.homework_rating, "homework")}
              </div>
            </td>
          </tr>
          <tr>
            <th>총합평점</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                <input
                  type="number"
                  className="small"
                  {...attributes("owner_rating")}
                  step={0.1}
                  min={0}
                />
                <span className="desc">
                  *지난달 평점
                  <br /> 3.7점 입니다.
                </span>
              </div>
            </td>
          </tr>
        </table>
        <div className="btn_group desktop">
          <button></button>
          <button className="btn_default half" onClick={submitData}>
            등록
          </button>
        </div>
        <div className="btn_group mobile">
          <button></button>
          <button className="btn_default " onClick={submitData}>
            등록
          </button>
        </div>
      </section>
    </div>
  );
}
